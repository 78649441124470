import { Box, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { useFormContext } from '../../contexts/FormContext';
import { LoadingButton } from '@mui/lab';
import { colorPrincipal, colorSecundario } from './consts';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Step4 = ({ offertJob }: { offertJob: string[] }) => {
  const { t } = useTranslation();
  const { resetForm } = useFormContext();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const handleGoBack = () => {
    resetForm();
  };

  const styleButton = {
    padding: '15px 38px',
    borderRadius: '8px',
  };

  const copyOfferToClipboard = (index: number) => {
    const textToCopy = offertJob[index];
    navigator.clipboard.writeText(textToCopy);
    setSnackbarOpen(true);
  };

  const copyQuestion = () => {
    navigator.clipboard.writeText(offertJob[3]!! + '');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const sections = [
    {
      title: t('Descripción y funciones:'),
      content: offertJob[0],
      copyIndex: 0,
      buttonText: t('Copiar descripción y funciones'),
    },
    {
      title: t('Requisitos:'),
      content: offertJob[1],
      copyIndex: 1,
      buttonText: t('Copiar Requisitos'),
    },
    {
      title: t('¿Qué se ofrece?:'),
      content: offertJob[2],
      copyIndex: 2,
      buttonText: t('Copiar ¿Qué se ofrece?'),
    },
  ];

  return (
    <>
      <Stack overflow={'auto'} gap={'32px'}>
        <Box>
          {sections.map((section, index) => (
            <div key={index}>
              <Typography fontWeight={600} fontSize="18px">
                {section.title}
              </Typography>
              <p
                style={{
                  width: '100%',
                  padding: '20px',
                  borderRadius: '8px',
                  border: `1px solid ${colorPrincipal}`,
                  fontSize: '16px',
                  whiteSpace: 'pre-line',
                }}
              >
                {section.content}
              </p>
              <LoadingButton
                sx={{
                  ...styleButton,
                  backgroundColor: colorPrincipal,
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '20px',
                  '&:hover': {
                    backgroundColor: colorSecundario,
                  },
                }}
                onClick={() => copyOfferToClipboard(section.copyIndex)}
                variant="contained"
              >
                {section.buttonText}
              </LoadingButton>
            </div>
          ))}

          <Typography fontWeight={600} fontSize="18px">
            {t('Preguntas sugeridas para el candidato:')}
          </Typography>
          <p
            style={{
              width: '100%',
              // height: '600px',
              padding: '20px',
              borderRadius: '8px',
              border: `1px solid ${colorPrincipal}`,
              fontSize: '16px',
              whiteSpace: 'pre-line',
            }}
          >
            {offertJob[3]}
          </p>
        </Box>
        <Stack
          justifyContent="space-between"
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={1}
        >
          <LoadingButton
            sx={{
              ...styleButton,
              backgroundColor: colorPrincipal,
              color: 'white',
              '&:hover': {
                backgroundColor: colorSecundario,
              },
            }}
            type="button"
            variant={'contained'}
            onClick={copyQuestion}
          >
            {t('Copiar preguntas')}
          </LoadingButton>

          <LoadingButton
            sx={{
              ...styleButton,
              borderColor: colorPrincipal,
              color: colorPrincipal,
              '&:hover': {
                backgroundColor: colorSecundario,
                borderColor: colorSecundario,
              },
            }}
            type="button"
            variant={'outlined'}
            onClick={handleGoBack}
          >
            {t('Crear nueva oferta')}
          </LoadingButton>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={t('¡Texto copiado!')}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default Step4;
