import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_BRAND } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext';
import BrandPersonalForm from './BrandPersonalForm';
import { BrandInput } from './BrandData';
import * as Yup from 'yup';
import useBrandForm from '../../hooks/useBrandForm';
import { TypeBrand } from '../../__generated__/graphql';
import { Close } from '@mui/icons-material';

const styleChip = {
  color: '#BBBBBB',
  background: 'white',
  height: '30px',
  border: '1px solid rgba(255, 0, 122, 1)',
  padding: '0px 5px',
  '& .MuiChip-label': {
    color: 'rgba(255, 0, 122, 1)',
  },
  '&:hover': {
    backgroundColor: '#E7EBF0',
  },
};

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  marginBottom: '10px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  width: '170px',
};

interface BrandSettingsModalProps {
  onClose: () => void;
}

const BrandSettingsModal = ({ onClose }: BrandSettingsModalProps) => {
  const { user, refetchCurrentUser } = useContext(AuthContext);

  const [createBrand] = useMutation(CREATE_BRAND, {
    onCompleted: () => {
      refetchCurrentUser();
    },
  });

  const schema = Yup.object().shape({
    position: Yup.string().required('Position is required'),
    targetBrand: Yup.array().test('required', 'Target Brand is required', (value) => {
      if (value!.length === 0) {
        return false;
      }
      return true;
    }),
    targetPublic: Yup.string().required('Target Public is required'),

    themeTalk: Yup.string().required('Theme Talk is required'),
    toneVoice: Yup.array().test('required', 'Tone of Voice is required', (value) => {
      if (value!.length === 0) {
        return false;
      }
      return true;
    }),
  });

  const { control, errors, handleSubmit } = useBrandForm({
    schema,
    brandData: null,
  });

  const onSuccess = (input: BrandInput) => {
    const {
      language,
      competitors,
      hashtags,
      negatives,
      industry,
      subIndustry,
      competitiveAdvantage,
      otherIndustries,
      toneVoice,
      position,
      targetBrand,
      targetPublic,
      themeTalk,
      urlProfile,
      signature,
    } = input;

    createBrand({
      variables: {
        input: {
          typeBrand: TypeBrand.Personal,
          name: user?.firstName
            ? `${user!.firstName} ${user!.lastName}`
            : (user?.email as string),
          contentCreation: {
            language: language,
            industry: industry,
            subIndustry: subIndustry,
            otherIndustries: otherIndustries,
            competitiveAdvantage: competitiveAdvantage,
            toneVoice: toneVoice,
            hashtags: hashtags,
            competitors: competitors,
            negatives: negatives,
            position: position,
            targetBrand: targetBrand,
            targetPublic: targetPublic,
            themeTalk: themeTalk,
          },
          shellId: user?.shellId as string,
          userId: user!._id,
          urlProfile: urlProfile,
          signature: signature,
        },
      },
    });
  };

  return (
    <>
      <Box padding={2}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
        >
          <Typography fontSize="25px" fontWeight={700}>
            Create your brand
          </Typography>

          <Close onClick={onClose} />
        </Stack>

        <form onSubmit={handleSubmit(onSuccess)} style={inputStyle}>
          <Stack direction="column">
            <Typography>
              Let's start shaping your brand! Enter your brand details to tailor your
              experience. Choose how you prefer to communicate and collaborate with your
              brand. We're here to support you every step of the way
            </Typography>
            <Divider
              sx={{
                marginY: 2,
              }}
            />
            <Stack flexDirection="row" justifyContent="flex-end" gap={2} paddingRight={1}>
              <Typography fontWeight={700}>Yes</Typography>
              <Typography fontWeight={700}>No</Typography>
            </Stack>

            <BrandPersonalForm
              control={control}
              inputStyle={inputStyle}
              styleChip={styleChip}
              errors={errors}
              isModal
              signatureRef={null}
            />

            <Stack flexDirection="row" justifyContent="flex-end" marginTop={2}>
              <Button
                variant="contained"
                type="button"
                sx={buttonStyle}
                onClick={() => {
                  handleSubmit(onSuccess)();
                }}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default BrandSettingsModal;
