import { useMutation } from '@apollo/client';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { useContext, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UNSUBSCRIBE_USER, SUBSCRIBE_USER } from '../../graphql/mutations';
import SettingsSection from '../SettingsSection/SettingsSection';
import { NotificationMethod, NotificationType } from '../../__generated__/graphql';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

// Define grouped notification types
const groupedNotificationTypes = {
  linkedinConnectionExpires: [NotificationType.LinkedinConnectionExpires],
  activityOnYourPosts: [NotificationType.NewPost, NotificationType.PostStatusChange],
  newFeedbackOnYourPosts: [NotificationType.NewMessage],
};

// Define the shape of notificationsEnabled
type NotificationsEnabledState = {
  [key in NotificationType]: {
    email: boolean;
    app: boolean;
  };
};

const SettingsNotifications = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  // Initialize notificationsEnabled state
  const userNotifications = user?.notifications || {};
  const initialNotificationsEnabled = Object.values(NotificationType).reduce(
    (acc, type) => {
      acc[type as NotificationType] = {
        email: userNotifications?.email?.includes(type) || false,
        app: userNotifications?.app?.includes(type) || false,
      };
      return acc;
    },
    {} as NotificationsEnabledState,
  );

  const [notificationsEnabled, setNotificationsEnabled] =
    useState<NotificationsEnabledState>(initialNotificationsEnabled);

  const [UnsubscribeUser] = useMutation(UNSUBSCRIBE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const [SubscribeUser] = useMutation(SUBSCRIBE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const onSuccess = () => {
    const userId = user?._id || '';

    const handleGroupedNotifications = (
      types: NotificationType[],
      method: NotificationMethod,
      enabled: boolean,
    ) => {
      types.forEach((type) => {
        enabled
          ? SubscribeUser({
              variables: {
                userId,
                type,
                notificationMethod: method,
              },
            })
          : UnsubscribeUser({
              variables: {
                userId,
                type,
                notificationMethod: method,
              },
            });
      });
    };

    // Handle each notification group
    Object.entries(groupedNotificationTypes).forEach(([group, types]) => {
      types.forEach((type) => {
        const emailEnabled = notificationsEnabled[type].email;
        const appEnabled = notificationsEnabled[type].app;

        handleGroupedNotifications(types, NotificationMethod.Email, emailEnabled);
        handleGroupedNotifications(types, NotificationMethod.App, appEnabled);
      });
    });
  };

  const handleToggleGroup = (
    group: keyof typeof groupedNotificationTypes,
    method: keyof NotificationsEnabledState[NotificationType],
  ) => {
    setNotificationsEnabled((prev) => {
      const newState = { ...prev };
      const groupState = groupedNotificationTypes[group].every(
        (type) => prev[type][method],
      );
      groupedNotificationTypes[group].forEach((type) => {
        newState[type][method] = !groupState;
      });
      return newState;
    });
  };

  return (
    <>
      <FormContainer onSuccess={onSuccess}>
        {/* Email Notifications */}
        <SettingsSection
          title={t('Notification Email')}
          subtitle={t('Opt out from receiving email updates')}
          divider={true}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    notificationsEnabled[NotificationType.LinkedinConnectionExpires].email
                  }
                  onChange={() =>
                    setNotificationsEnabled((prev) => ({
                      ...prev,
                      [NotificationType.LinkedinConnectionExpires]: {
                        ...prev[NotificationType.LinkedinConnectionExpires],
                        email: !prev[NotificationType.LinkedinConnectionExpires].email,
                      },
                    }))
                  }
                />
              }
              label={t('LinkedIn account status')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t('Your LinkedIn connection is about to expire or has expired.')}
            </p>
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={groupedNotificationTypes.activityOnYourPosts.every(
                    (type) => notificationsEnabled[type].email,
                  )}
                  onChange={() => handleToggleGroup('activityOnYourPosts', 'email')}
                />
              }
              label={t('Activity on your posts')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t(
                'A post is ready to share, someone requests your approval or a post changed status.',
              )}
            </p>
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationsEnabled[NotificationType.NewMessage].email}
                  onChange={() =>
                    setNotificationsEnabled((prev) => ({
                      ...prev,
                      [NotificationType.NewMessage]: {
                        ...prev[NotificationType.NewMessage],
                        email: !prev[NotificationType.NewMessage].email,
                      },
                    }))
                  }
                />
              }
              label={t('New feedback on your posts')}
            />
            <p style={{ marginLeft: '32px' }}>
              {t('Get notified when new comments are available on your posts.')}
            </p>
          </div>
        </SettingsSection>

        {/* App Notifications THIS OPTION FOR NOW WE DONT MAKE IT VISIBLE BECAUSE THE APP NOTIFICATIONS ARE FOR ALL THE USERS THEY DONT HAVE THE POSSIBILITY TO DESACTIVATE THEM */}
        {/*  
        {user?.role?.includes(UserRole.Admin) && (
          <SettingsSection
            title={t('App')}
            subtitle={t('Opt in or out from receiving app notifications')}
            divider={true}
          >
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={groupedNotificationTypes.activityOnYourPosts.every(
                      (type) => notificationsEnabled[type].app,
                    )}
                    onChange={() => handleToggleGroup('activityOnYourPosts', 'app')}
                  />
                }
                label={t('Activity on your posts')}
              />
              <p style={{ marginLeft: '32px' }}>
                {t(
                  'A post is ready to share, someone requests your approval or a post changed status.',
                )}
              </p>
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsEnabled[NotificationType.NewMessage].app}
                    onChange={() =>
                      setNotificationsEnabled((prev) => ({
                        ...prev,
                        [NotificationType.NewMessage]: {
                          ...prev[NotificationType.NewMessage],
                          app: !prev[NotificationType.NewMessage].app,
                        },
                      }))
                    }
                  />
                }
                label={t('New feedback on your posts')}
              />
              <p style={{ marginLeft: '32px' }}>
                {t('Get notified when new comments are available on your posts.')}
              </p>
            </div>
          </SettingsSection>
        )}
        */}

        <Button variant="contained" type="submit" sx={inputStyle}>
          {t('Save')}
        </Button>
      </FormContainer>
    </>
  );
};

export default SettingsNotifications;
