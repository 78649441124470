import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { AuthContext } from './AuthContext';

export interface WebSocketContextType {
  socket: Socket | null; // For `/post` namespace
  userSocket: Socket | null; // For global events
  isSocketConnected: boolean;
  isUserSocketConnected: boolean;
}

const initialState: WebSocketContextType = {
  socket: null,
  userSocket: null,
  isSocketConnected: false,
  isUserSocketConnected: false,
};

export const WebSocketContext = createContext<WebSocketContextType>(initialState);

export const WebSocketProvider = ({
  children,
  postId,
  isUserSocket = false,
}: {
  children: React.ReactNode;
  postId?: string | null;
  isUserSocket?: boolean;
}) => {
  const [socket, setSocket] = useState<Socket | null>(null); // For `/post`
  const [userSocket, setUserSocket] = useState<Socket | null>(null); // For /user
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [isUserSocketConnected, setIsUserSocketConnected] = useState<boolean>(false);
  const { user, loading, anonymousId } = useContext(AuthContext);

  const socketRef = useRef<Socket | null>(null);
  const userSocketRef = useRef<Socket | null>(null);

  // Keep socket references updated
  useEffect(() => {
    socketRef.current = socket;
    userSocketRef.current = userSocket;
  }, [socket, userSocket]);

  // Establish connection for `/post` namespace
  useEffect(() => {
    if (postId && !loading) {
      
      const socketUrl = `${process.env.REACT_APP_WS_SERVER_URL}/post`;
      const queryParams: Record<string, string> = { postId };

      if (user?._id) {
        queryParams.userId = user._id;
      } else {
        queryParams.anonymousId = anonymousId;
      }

      const newSocket = io(socketUrl, { query: queryParams, path: '/post' });

      newSocket.on('connect', () => {
        setIsSocketConnected(true);
      });

      newSocket.on('disconnect', () => {
        setIsSocketConnected(false);
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [postId, user?._id, loading, anonymousId]);

  // Establish connection for global events
  useEffect(() => {
    if (isUserSocket && !loading) {
      const mainSocketUrl = `${process.env.REACT_APP_WS_SERVER_URL}/user`;
      const newMainSocket = io(mainSocketUrl, { path: '/user' }); // Connect to user server

      newMainSocket.on('connect', () => {
        setIsUserSocketConnected(true);
      });

      newMainSocket.on('disconnect', () => {
        setIsUserSocketConnected(false);
      });

      setUserSocket(newMainSocket);

      return () => {
        newMainSocket.disconnect();
      };
    }
  }, [isUserSocket, loading]);

  return (
    <WebSocketContext.Provider
      value={{
        socket, // For `/post` namespace
        userSocket, // For global events
        isSocketConnected,
        isUserSocketConnected,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
