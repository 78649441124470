import { useMutation, useQuery } from '@apollo/client';
import { FilterAlt, Queue } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CreateIdeaInput,
  OrderByDirection,
  OrderByField,
} from '../../__generated__/graphql';
import { BatchCreateIdeas } from '../../components/BatchCreateIdeas/BatchCreateIdeas';
import { IdeasFilter } from '../../components/Filters/IdeasFilter';
import IdeasTable from '../../components/Tables/IdeasTable';
import { DeviceContext } from '../../context/DeviceContext';
import { IdeasFilterContext } from '../../context/IdeasFilterContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_IDEAS } from '../../graphql/mutations';
import { GET_BRANDS, GET_CHAINS, GET_IDEAS } from '../../graphql/queries';
import { OrderBy } from '../../types';
import { useTranslation } from 'react-i18next';

const IdeasTab = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const { filter, setFilter, filterActive } = useContext(IdeasFilterContext);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });
  const [openGenerateBatchIdeas, setOpenGenerateBatchIdeas] = useState<boolean>(false);

  const {
    data,
    refetch,
    loading: loadingGetIdeas,
  } = useQuery(GET_IDEAS, {
    variables: {
      filter,
      page,
      limit,
      orderBy,
    },
    fetchPolicy: 'network-only',
  });

  let ideas = data?.getIdeas.ideas || [];
  ideas = ideas.filter(
    (idea) => !idea.prompterRunIds || idea.prompterRunIds.length === 0,
  );
  const total = data?.getIdeas.total || 0;

  const { data: dataChains } = useQuery(GET_CHAINS);
  const { data: dataBrands } = useQuery(GET_BRANDS, { variables: { allBrands: true } });
  const chains = dataChains?.chains || [];
  const brands = dataBrands?.getBrands || [];

  const [createIdea, { loading }] = useMutation(CREATE_IDEAS);

  const { control, handleSubmit, reset } = useForm<CreateIdeaInput>({
    defaultValues: {
      brandId: '',
      content: '',
      chainId: '',
      priority: 0,
    },
  });

  const onSuccess = (data: CreateIdeaInput) => {
    const brand = brands.find((brand) => brand._id === data.brandId);
    const chain = chains.find((chain) => chain._id === data.chainId);

    if (!chain || !brand) {
      setErrorMessage(t('Something went wrong'));
      return;
    }

    createIdeas([
      {
        brandId: data.brandId,
        brandName: brand.name,
        chainId: data.chainId,
        chainName: chain.name,
        shellId: brand.shellId,
        content: data.content,
        priority: Number(data.priority),
      },
    ]);
  };

  const createIdeas = (ideas: CreateIdeaInput[]) => {
    createIdea({
      variables: {
        input: ideas.map((idea) => ({
          ...idea,
          chainName: chains.find((chain) => chain._id === idea.chainId)?.name || '',
        })),
      },
      onCompleted: ({ createIdeas }) => {
        if (!createIdeas.success) {
          setErrorMessage(createIdeas.message || t('There was an error the ideas'));
          return;
        }

        reset();

        setSuccessMessage(
          createIdeas.message ||
            `${createIdeas.ideas?.length} ${t('ideas created successfully')}`,
        );

        if (page !== 0) {
          setPage(0);
          return;
        }

        refetch({
          page,
          limit,
          orderBy,
        });
      },
    });
  };

  if (loadingGetIdeas) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      {/* <LanguageSwitcher /> */}
      <Stack direction="column" spacing={1} alignItems={isMobile ? 'center' : 'start'}>
        <Stack
          direction={'row'}
          width={'100%'}
          justifyContent={'flex-end'}
          my={2}
          gap={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setFiltersOpen(true)}
            data-testid="filter-button"
            sx={{
              backgroundColor: filterActive ? '#FFD8EB' : 'white',
            }}
          >
            <FilterAlt sx={{ marginRight: '10px' }} />
            <Typography fontWeight="bold">{t('Filter')}</Typography>
          </Button>
          <Button
            startIcon={<Queue />}
            variant="contained"
            sx={{ width: 'fit-content' }}
            onClick={() => setOpenGenerateBatchIdeas(true)}
          >
            {t('Generate ideas in batch')}
          </Button>
        </Stack>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            padding: '10px 25px',
            borderRadius: '8px',
            width: '100%',
            boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
            backgroundColor: 'white',
          }}
        >
          <Stack
            component="form"
            direction="row"
            spacing={2}
            alignItems="start"
            justifyContent="center"
            sx={{ my: 2, width: '100%' }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSuccess)}
          >
            <Controller
              name="brandId"
              control={control}
              defaultValue=""
              rules={{ required: t('Brand selection is required') }} // Added rules for required validation
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  value={brands.find((chain) => chain._id === field.value) || null}
                  onChange={(_, newValue) => field.onChange(newValue ? newValue._id : '')}
                  sx={{ flex: 3 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Brand')}
                      variant="outlined"
                      required
                      error={!!fieldState.error} // Display error state
                      helperText={fieldState.error ? fieldState.error.message : null} // Display error message
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Stack
                        direction={'column'}
                        justifyContent={'space-between'}
                        width={'100%'}
                      >
                        <Typography fontWeight={500}>{option.name}</Typography>
                        <Typography fontSize={14}>{option.user?.email}</Typography>
                      </Stack>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="content"
              control={control}
              defaultValue=""
              rules={{ required: t('Content is required') }} // Updated to provide a custom message
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="content"
                  multiline
                  label={t('Content')}
                  variant="outlined"
                  required
                  sx={{ flex: 8 }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!fieldState.error} // Added to show error state
                  helperText={fieldState.error ? fieldState.error.message : null} // Added to show error message
                />
              )}
            />
            <Controller
              name="chainId"
              control={control}
              rules={{ required: t('Chain is required') }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={chains}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  value={chains.find((chain) => chain._id === field.value) || null}
                  onChange={(_, newValue) => field.onChange(newValue ? newValue._id : '')}
                  sx={{ flex: 4 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Chain')}
                      variant="outlined"
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                />
              )}
            />

            <Controller
              name="priority"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ flex: 2 }} size="small">
                  <InputLabel id="priority-label" shrink>
                    {t('Priority')}
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="priority-label"
                    id="idea-priority"
                    label={t('Priority')}
                    required
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={0}>{t('Low')}</MenuItem>
                    <MenuItem value={1}>{t('Medium')}</MenuItem>
                    <MenuItem value={2}>{t('High')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <LoadingButton
              loading={loading}
              startIcon={<AddIcon />}
              variant="contained"
              type="submit"
            >
              {t('Add idea')}
            </LoadingButton>
          </Stack>
        </Card>

        <IdeasTable
          ideas={ideas}
          page={page}
          onPageChange={setPage}
          total={total}
          limit={limit}
          setLimit={setLimit}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      </Stack>
      <IdeasFilter
        filter={filter}
        setFilter={setFilter}
        setPage={setPage}
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
      />
      {openGenerateBatchIdeas && (
        <BatchCreateIdeas
          open={openGenerateBatchIdeas}
          brands={brands}
          chains={chains}
          onClose={() => setOpenGenerateBatchIdeas(false)}
          onCreateIdeas={createIdeas}
        />
      )}
    </>
  );
};

export default IdeasTab;
