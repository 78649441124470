import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';
import {
  companyDescriptionPlaceholder,
  jobDescriptionPlaceholder,
  jobLevelOptions,
} from './consts';
import { JobDescriptionInput } from '../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

const Step2 = ({ onSubmit }: { onSubmit: (data: JobDescriptionInput) => void }) => {
  const { t } = useTranslation();
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <TextField
        control={control}
        name="companyDescription"
        multiline
        autoFocus
        placeholder={t(companyDescriptionPlaceholder)}
        label={t('Proporciona una descripción de tu empresa, negocio y su actividad.')}
      />
      <TextField
        control={control}
        name="jobDescription"
        multiline
        placeholder={t(jobDescriptionPlaceholder)}
        label={t(
          'Proporciona una breve descripción de las actividades que realizará la persona en el cargo.',
        )}
      />
      <Select
        options={jobLevelOptions.map((attr) => ({
          label: t(attr.label),
          value: attr.value,
        }))}
        control={control}
        name="jobLevel"
        label={t('¿A qué nivel corresponde este puesto?')}
        placeholder={t('Ej: Junior')}
      />

      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        buttonId="lm-job-description-step-2-button"
      />
    </Stack>
  );
};

export default Step2;
