import {
  CalendarMonth,
  Close,
  Create,
  Label,
  Lightbulb,
  Message as MessageIcon,
  ArrowBack,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import {
  PostErrorCode,
  PostLifecycleState,
  PostType,
  Tag,
  UserRole,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostAdvocacyMembersProvider } from '../../context/PostAdvocacyMembersContext';
import { PostContext } from '../../context/PostContext';
import { DefaultErrorMessage, StatusCode } from '../../errors/constants';
import { dateString, formatDate, sliceText, timeString } from '../../utils';
import Avatar from '../Avatar/Avatar';
import ThumbnailCard from '../Cards/ThumbnailCard';
import DatePicker from '../DatePicker/DatePicker';
import MagnetsSelector from '../SelectorComponents/MagnetsSelector';
import { TextEditor } from '../TextEditor/TextEditor';
import BrandsSelector from './BrandsSelector';
import PostActions from './PostActions';
import PostMediaDisplay from './PostMediaDisplay';
import { PostStateSelector } from './PostStateSelector';
import PostTags from './PostTags';
import TagModal from './TagModal';
import { useTranslation } from 'react-i18next';
import { PostOptions } from './PostOptions';
import Chat from '../ChatPost/Chat';

export interface Message {
  text: string;
  type: 'error' | 'success' | 'info' | 'warning';
  action?: {
    text: string;
    onClick: () => void;
  };
}

interface Props {
  onClose?: (force?: boolean) => void;
  openChat: boolean;
  setOpenChat: (open: boolean) => void;
  isLink: boolean;
  isLinkInbox?: boolean;
  isInsight?: boolean;
}

const PostEditor = ({
  onClose = (force?: boolean) => null,
  openChat,
  setOpenChat,
  isLink,
  isLinkInbox,
  isInsight,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<Message | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);

  const { user } = useContext(AuthContext);

  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { handleBrandSelected } = useContext(BrandContext);

  const { post, status, handlers, error, advocacyPostInput, hidePost } = postState ?? {};

  const [title, setTitle] = useState(post?.title || '');
  const schedule = post?.schedule ? new Date(post?.schedule) : null;

  let displayMessage: Message | null = message;

  // const deletePost =
  //   !status?.isAdvocacyParent &&
  //   post?.lifecycleState &&
  //   [
  //     PostLifecycleState.Draft,
  //     PostLifecycleState.Discarded,
  //     PostLifecycleState.Error,
  //   ].includes(post?.lifecycleState);

  if (!post?.brand && post?.type !== PostType.AdvocacyParent) {
    displayMessage = {
      text: 'This post has no brand assigned or it might have been deleted',
      type: 'error',
    };
  } else if (
    (error &&
      [
        StatusCode.LINKEDIN_TOKEN_NOT_FOUND,
        StatusCode.LINKEDIN_TOKEN_ABOUT_TO_EXPIRE,
        StatusCode.LINKEDIN_TOKEN_ERROR,
      ].includes(error.code)) ||
    post.errorCode === PostErrorCode.LinkedinToken
  ) {
    if (post.brandId === user?.brandId) {
      displayMessage = {
        text: DefaultErrorMessage.LINKEDIN_TOKEN_ERROR,
        type: 'error',
        action: {
          text: 'Go to settings',
          onClick: () => {
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({ callback: () => navigate('/settings') });
            } else {
              navigate('/settings');
            }
          },
        },
      };
    } else {
      displayMessage = {
        text: DefaultErrorMessage.LINKEDIN_TOKEN_ERROR_BRAND,
        type: 'error',
        action: {
          text: 'Go to brands',
          onClick: () => {
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({ callback: () => navigate('/brands') });
            } else {
              navigate('/brands');
            }
          },
        },
      };
    }
  } else if (post?.type !== PostType.AdvocacyParent && !post?.brand?.account) {
    if (post?.brandId === user?.brandId) {
      displayMessage = {
        text: t(
          "You don't have any LinkedIn accounts connected. You can add one in settings.",
        ),
        type: 'error',
        action: {
          text: t('Save and go to settings 👉'),
          onClick: () => {
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({ callback: () => navigate('/settings') });
            } else {
              navigate('/settings');
            }
          },
        },
      };
    } else {
      displayMessage = {
        text:
          `${post.brand?.name} ` +
          t(
            "doesn't have a LinkedIn account connected. You can connect one for them from Brands",
          ),
        type: 'error',
        action: {
          text: t('Save and go to brands 👉'),
          onClick: () => {
            handleBrandSelected(post.brand!);
            window.scrollTo(0, 0);
            if (status?.isNew) {
              handlers?.handleSaveAsDraft({
                callback: () => {
                  navigate('/brands');
                },
              });
            } else {
              navigate('/brands');
            }
          },
        },
      };
    }
  } else if (error) {
    displayMessage = {
      text: error.message || t('An error occurred'),
      type: 'error',
    };
  } else if (post?.lifecycleState === PostLifecycleState.Error) {
    displayMessage = {
      text: t('There was an error publishing your post. Please try again.'),
      type: 'error',
    };
  } else if (post?.content?.body?.replace(/<[^>]*>?/gm, '').length! > 3000) {
    displayMessage = {
      text: t('You have exceeded the maximum character limit'),
      type: 'error',
    };
  } else if (schedule && post?.lifecycleState === PostLifecycleState.Scheduled) {
    displayMessage = {
      type: 'success',
      text: t('Your post is scheduled for {{date}} at {{time}}', {
        date: dateString(schedule),
        time: timeString(schedule),
      }),
    };
  } else if (status?.isPosted) {
    displayMessage = {
      text: t(
        'You have already posted this content. (This post may take a hour to appear on LinkedIn)',
      ),
      type: 'info',
    };
  } else if (
    post.lifecycleState === PostLifecycleState.Posting &&
    post.content?.media?.[0]?.type?.includes('video')
  ) {
    displayMessage = {
      text: t(
        'Uploading media... This may take a while. You can close the editor while we finish posting it.',
      ),
      type: 'info',
    };
  }

  const gridRef = useRef<HTMLDivElement>(null);
  let content = null;
  if (!post?.content?.json && !post?.advocacy?.parentPost?.content?.json) {
    content = post?.content?.body || post?.advocacy?.parentPost?.content?.body;
  }

  if (post?.type === PostType.AdvocacyParent) {
    content = post?.content?.body;
  }

  if (
    !user?.role.includes(UserRole.BrandManager) &&
    !user?.role.includes(UserRole.LimitedBrandManager) &&
    !user?.role.includes(UserRole.PostChatUser)
  ) {
    content = post?.content?.body || post?.advocacy?.parentPost?.content?.body;
  }

  const handleOpenDatepicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSubmit = (date: Date) => {
    setAnchorEl(null);
    handlers?.handleDateChange(date);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes of inactivity
    onIdle: () => {
      onClose();
    },
    debounce: 500,
    disabled: postState?.status.isNew,
  });

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape' || event.key === 'Tab') {
      handlers?.handleTitleChange(title);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    handlers?.handleTitleChange(title);
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
  };

  return (
    <PostAdvocacyMembersProvider data-name="PostAdvocacyMembersProvider">
      {openChatModal && (
        <Dialog
          data-name="ChatDialog"
          open={openChatModal}
          onClose={() => setOpenChatModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent data-name="ChatDialogContent">
            <Box
              data-name="ChatDialogBox"
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <Stack
                data-name="ChatDialogStack"
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <IconButton
                  data-name="ChatDialogBackButton"
                  onClick={() => setOpenChatModal(false)}
                >
                  <ArrowBack />
                </IconButton>
              </Stack>
            </Box>
            <Box data-name="ChatDialogBoxContent" sx={{ mt: 2 }}>
              <Chat
                data-name="ChatComponent"
                messages={post?.chat || []}
                handleSendMessage={(message) => handlers?.handleSendChatMessage(message)}
                writing={false}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <Stack
        data-name="MainStack"
        direction={{ xs: 'column', md: 'row' }}
        gap={2}
        sx={{ width: '100%' }}
      >
        <DatePicker
          data-name="DatePicker"
          date={post?.schedule}
          open={anchorEl !== null}
          withTimestamp={false}
          maxDate={new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)}
          anchorElement={anchorEl!}
          onClose={() => setAnchorEl(null)}
          onSubmit={handleDateSubmit}
          primaryActionTitle={t('Save')}
          transformOrigin="top end"
          isMobile={isMobile}
          tentative
        />
        <Grid
          data-name="MainGrid"
          ref={gridRef}
          container
          rowSpacing={2}
          columnSpacing={1}
          sx={{ width: '100%' }}
        >
          <Grid item xs={12} md={8} data-name="TitleGrid">
            <Stack
              data-name="TitleStack"
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                data-name="TitleInnerStack"
                flexDirection="row"
                gap={{ xs: 1, md: 2 }}
                marginBottom={1}
                alignContent={'center'}
                alignItems={'center'}
              >
                <Stack data-name="TitleTextFieldStack">
                  {isEditing ? (
                    <TextField
                      data-name="TitleTextField"
                      required
                      value={title || ''}
                      onChange={(value) => {
                        onChangeTitle(value.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      sx={{ maxWidth: '180px' }}
                      placeholder={t('Untitled post')}
                      autoFocus
                      variant="standard"
                    />
                  ) : (
                    <Stack
                      data-name="TitleTypographyStack"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                    >
                      <Typography
                        data-name="TitleTypography"
                        fontWeight={700}
                        marginLeft={1}
                        maxWidth="180px"
                      >
                        {sliceText(title, 22) ||
                          sliceText(post?.title as string, 22) ||
                          t('Untitled post')}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                {!isEditing && (
                  <IconButton
                    data-name="EditTitleButton"
                    onClick={toggleEditMode}
                    disabled={status?.isEditDisabled || isLink}
                  >
                    <Create />
                  </IconButton>
                )}

                {!isMobile &&
                  (user || post?.shareLink) &&
                  post?.type !== PostType.AdvocacyParent &&
                  post?.lifecycleState &&
                  post?.lifecycleStateDisplay &&
                  handlers && (
                    <PostStateSelector
                      data-name="PostStateSelector"
                      lifecycleStateDisplay={post.lifecycleStateDisplay}
                      lifecycleState={post.lifecycleState}
                    />
                  )}
                {post?.lifecycleState === PostLifecycleState.Draft &&
                  (user?.role.includes(UserRole.BrandManager) ||
                    user?.role.includes(UserRole.LimitedBrandManager)) && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      {status?.isNew ? (
                        hidePost ? ( // For new posts, use local hidePost state
                          <>
                            <VisibilityOff />
                            <Typography fontSize={16} fontWeight="bold">
                              {t('Hide')}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Visibility sx={{ color: '#E91E63' }} />
                            <Typography fontSize={16} color="#E91E63" fontWeight="bold">
                              {t('Visible')}
                            </Typography>
                          </>
                        )
                      ) : postState?.post?.hidePost ? ( // For existing posts, use postState?.post?.hidePost
                        <>
                          <VisibilityOff />
                          <Typography fontSize={16} fontWeight="bold">
                            {t('Hide')}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Visibility sx={{ color: '#E91E63' }} />
                          <Typography fontSize={16} color="#E91E63" fontWeight="bold">
                            {t('Visible')}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  )}
              </Stack>

              {isMobile && (
                <IconButton
                  data-name="CloseButtonMobile"
                  onClick={() => onClose()}
                  sx={{
                    marginTop: '-8px',
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Stack>
          </Grid>
          {isMobile && (
            <Grid item xs={12} data-name="MobileGrid">
              <Stack
                data-name="MobileStack"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {((!user && post?.brandId) ||
                  user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)) &&
                  post?.type !== PostType.AdvocacyParent && (
                    <Stack
                      data-name="MobileAvatarStack"
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Avatar
                        data-name="MobileAvatar"
                        size={40}
                        name={post?.brand?.name || ''}
                        avatar={post?.brand?.account?.avatar || ''}
                        backgroundColor={post?.brand?.backgroundColor || ''}
                      />
                      <Typography data-name="MobileBrandName" fontWeight={'bold'}>
                        {post?.brand?.name || 'Unknown'}
                      </Typography>
                    </Stack>
                  )}
                {(!user ||
                  user?.role.includes(UserRole.PostChatUser) ||
                  user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)) &&
                  post?.type !== PostType.AdvocacyParent &&
                  !status?.isNew && (
                    <Stack
                      data-name="MobileCommentsStack"
                      direction={'row'}
                      gap={1}
                      marginBottom={1}
                    >
                      <Badge
                        data-name="MobileCommentsBadge"
                        badgeContent={post?.unreadMessagesCount}
                        color="primary"
                        sx={{
                          width: '100%',
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        max={10}
                        invisible={!post?.unreadMessagesCount || openChat}
                      >
                        <Button
                          data-name="MobileCommentsButton"
                          variant="outlined"
                          fullWidth
                          startIcon={<MessageIcon />}
                          onClick={() => {
                            handlers?.handleUnreadMessagesChange(0);
                            setOpenChat(!openChat);
                          }}
                          sx={{
                            backgroundColor: openChat ? '#FFD8EB' : '#FFF',
                            justifyContent: 'left',
                          }}
                        >
                          {t('Comments')}
                        </Button>
                      </Badge>
                    </Stack>
                  )}
              </Stack>
              <Divider
                data-name="MobileDivider"
                sx={{
                  marginY: 1,
                }}
              />
              <Stack
                data-name="MobilePostStateStack"
                flexDirection="row"
                gap={1}
                justifyContent="space-between"
              >
                {(user || post?.shareLink) &&
                  post?.type !== PostType.AdvocacyParent &&
                  post?.lifecycleState &&
                  post?.lifecycleStateDisplay &&
                  handlers &&
                  !isMobile && (
                    <PostStateSelector
                      data-name="MobilePostStateSelector"
                      lifecycleStateDisplay={post.lifecycleStateDisplay}
                      lifecycleState={post.lifecycleState}
                    />
                  )}
                {isMobile && post?.lifecycleState && post?.lifecycleStateDisplay && (
                  <PostStateSelector
                    data-name="MobilePostStateSelector"
                    lifecycleStateDisplay={post.lifecycleStateDisplay}
                    lifecycleState={post.lifecycleState}
                  />
                )}
                <Divider
                  data-name="MobileVerticalDivider"
                  orientation="vertical"
                  flexItem
                />
                <Stack
                  data-name="MobileIdeaStack"
                  flexDirection="row"
                  alignItems="center"
                >
                  <FormControlLabel
                    data-name="MobileIdeaCheckbox"
                    control={
                      <Checkbox
                        checked={!!post?.isIdea}
                        disabled={status?.isEditDisabled || isLink}
                        onChange={(e) => handlers?.handlePostIdea(e.target.checked)}
                      />
                    }
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: { xs: '.8rem', sm: '1rem' },
                        marginLeft: '5px',
                      },
                      '& .MuiCheckbox-root': {
                        padding: 0,
                        marginLeft: '10px',
                      },
                    }}
                    label={t('This is an idea')}
                  />{' '}
                  <Lightbulb
                    data-name="MobileIdeaIcon"
                    sx={{
                      fontSize: { xs: '1.2rem', sm: '1.5rem' },
                      color: !!post?.isIdea ? '#ffb906' : '#000',
                      marginLeft: '-10px',
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}
          {!isMobile && (
            <Grid item xs={12} sm={6} data-name="DesktopGrid">
              {((!user && post?.brandId) ||
                user?.role.includes(UserRole.BrandManager) ||
                user?.role.includes(UserRole.LimitedBrandManager)) &&
                post?.type !== PostType.AdvocacyParent && (
                  <Stack
                    data-name="DesktopAvatarStack"
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Avatar
                      data-name="DesktopAvatar"
                      size={40}
                      name={post?.brand?.name || ''}
                      avatar={post?.brand?.account?.avatar || ''}
                      backgroundColor={post?.brand?.backgroundColor || ''}
                    />
                    <Typography data-name="DesktopBrandName" fontWeight={'bold'}>
                      {post?.brand?.name || 'Unknown'}
                    </Typography>
                  </Stack>
                )}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={10}
            data-name="TextEditorGrid"
            sx={{ zIndex: 0, height: { xs: 300, md: 405, lg: 700 }, overflow: 'auto' }}
          >
            <TextEditor
              data-name="TextEditor"
              isLink={isLink}
              key={'editor-with-post'}
              postId={post?._id}
              content={content}
              aiAssistant
              status={status}
              handlers={handlers}
              advocacyEditable={!!advocacyPostInput?.editable}
              toggleAdvocacyEditable={() => handlers?.handleToggleAdvocacyEditable()}
              isUndoable
              isAdmin={isInsight}
            />
          </Grid>
          <Grid alignSelf={'start'} item xs={2} data-name="BrandsSelectorGrid">
            {post?.type === PostType.AdvocacyParent && handlers && (
              <Stack
                data-name="BrandsSelectorStack"
                direction="row"
                width={'100%'}
                marginBottom={2}
              >
                <BrandsSelector
                  data-name="BrandsSelector"
                  onChange={handlers.handleRecipientsChange}
                  selectedIds={advocacyPostInput?.recipients?.map((r) => r.brandId) || []}
                  disabledIds={post?.childrenPosts
                    ?.filter((p) => p.lifecycleState === PostLifecycleState.Posted)
                    .map((p) => p.brandId!)}
                />
              </Stack>
            )}
            {!isMobile && (
              <Stack data-name="IdeaStack" direction={'row'} gap={1} marginBottom={1}>
                <FormControlLabel
                  data-name="IdeaCheckbox"
                  control={
                    <Checkbox
                      checked={!!post?.isIdea}
                      disabled={status?.isEditDisabled || isLink}
                      onChange={(e) => handlers?.handlePostIdea(e.target.checked)}
                    />
                  }
                  label={<Typography variant="body2">{t('This is an idea')}</Typography>}
                />{' '}
                <Lightbulb
                  data-name="IdeaIcon"
                  sx={{
                    color: !!post?.isIdea ? '#ffb906' : '#000',
                    marginLeft: '-10px',
                  }}
                />
              </Stack>
            )}
            {!isMobile &&
              (!user ||
                user?.role.includes(UserRole.PostChatUser) ||
                user?.role.includes(UserRole.BrandManager) ||
                user?.role.includes(UserRole.LimitedBrandManager)) &&
              post?.type !== PostType.AdvocacyParent &&
              !status?.isNew && (
                <Stack
                  data-name="CommentsStack"
                  direction={'row'}
                  gap={1}
                  marginBottom={1}
                >
                  <Badge
                    data-name="CommentsBadge"
                    badgeContent={post?.unreadMessagesCount}
                    color="primary"
                    sx={{
                      width: '100%',
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    max={10}
                    invisible={!post?.unreadMessagesCount || openChat}
                  >
                    <Button
                      data-name="CommentsButton"
                      variant="outlined"
                      fullWidth
                      startIcon={<MessageIcon />}
                      onClick={() => {
                        handlers?.handleUnreadMessagesChange(0);
                        setOpenChatModal(true);
                      }}
                      sx={{
                        backgroundColor: openChat ? '#FFD8EB' : '#FFF',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {t('Comments')}
                    </Button>
                  </Badge>
                </Stack>
              )}
            {!isMobile && (
              <Button
                data-name="LabelsButton"
                variant="outlined"
                fullWidth
                startIcon={<Label />}
                onClick={() => {
                  setOpenTagModal(true);
                }}
                disabled={
                  (status?.isEditDisabled && !status.isPosted) || isLink || isLinkInbox
                }
                sx={{
                  backgroundColor: !!post?.tags?.filter((tag) => !tag?.internal).length
                    ? '#30527C33'
                    : '#FFF',
                  marginBottom: 1,
                  justifyContent: 'flex-start', // Align text to the left
                }}
              >
                {t('Labels')}
              </Button>
            )}

            {!isMobile && !status?.isAdvocacyParent && (
              <Button
                data-name="DateButton"
                color="primary"
                fullWidth
                startIcon={<CalendarMonth />}
                endIcon={
                  schedule ? (
                    <Close
                      sx={{
                        width: '15px',
                        height: '15px',
                        '&:hover': { color: '#292929' },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlers?.handleDateChange(null);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
                disabled={
                  [PostLifecycleState.Scheduled, PostLifecycleState.Posted].includes(
                    post?.lifecycleState!,
                  ) || isLinkInbox
                }
                variant="outlined"
                onClick={handleOpenDatepicker}
                sx={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'flex-start',
                  fontSize: '11px',
                }}
              >
                {schedule ? formatDate(schedule) : t('Tentative Date')}
              </Button>
            )}
          </Grid>

          {status?.errorThumbnail &&
            status?.showErrorThumbnail &&
            !post?.content?.thumbnail && (
              <Grid item xs={12} data-name="ErrorThumbnailGrid">
                <Alert
                  data-name="ErrorThumbnailAlert"
                  severity="info"
                  onClose={() => handlers?.handleShowErrorThumbnail(false)}
                >
                  Cannot display link preview. You can post as is, or try another link.
                </Alert>
              </Grid>
            )}

          {!status?.errorThumbnail &&
          status?.loadingThumbnail &&
          !post?.content?.media?.length ? (
            <Grid item xs={12} data-name="LoadingThumbnailGrid">
              <Skeleton
                data-name="LoadingThumbnailSkeleton"
                variant="rectangular"
                height={60}
                sx={{ width: '100%', borderRadius: 1 }}
              />
            </Grid>
          ) : post?.content?.thumbnail?.title &&
            !post?.content?.media?.length &&
            postState?.status.showThumbnail ? (
            <Grid item xs={12} data-name="ThumbnailCardGrid">
              <ThumbnailCard
                data-name="ThumbnailCard"
                thumbnail={post.content.thumbnail}
                hideThumbnail={postState?.handlers.handleHideThumbnail}
                status={status}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={10}
              data-name="PostMediaDisplayGrid"
              marginTop={{
                xs: -2,
                md: 0,
              }}
              sx={{
                zIndex: 0,
                position: 'relative', // Constrain positioned children within this grid
                overflow: 'hidden', // Prevent content overflow
              }}
            >
              <PostMediaDisplay
                data-name="PostMediaDisplay"
                disabled={status?.isEditDisabled}
                post={post}
                onUploadedMedia={handlers?.handleUploadMedia}
                saveDraft={handlers?.handleSaveAsDraft}
                onDeletedMedia={handlers?.handleDeleteMedia}
                setMessage={(message) => setMessage(message)}
                isLinkInbox={isLinkInbox}
                displayMessage={!!displayMessage}
              />
            </Grid>
          )}

          {(!!post?.tags?.length || isMobile) && (
            <PostTags
              data-name="PostTags"
              tags={post?.tags as Tag[]}
              openModal={setOpenTagModal}
              isMobile={isMobile}
              handleRemoveTag={!isLinkInbox ? handlers?.handleRemoveTag : undefined}
            />
          )}

          {isMobile && !status?.isAdvocacyParent && (
            <Stack
              data-name="MobileDateStack"
              flexDirection="column"
              width="100%"
              paddingX={1}
            >
              <Divider
                data-name="MobileDateDivider"
                sx={{
                  marginY: 1,
                }}
              />

              <Typography data-name="MobileDateTypography" marginBottom={1}>
                Tentative Date
              </Typography>

              <Button
                data-name="MobileDateButton"
                color="primary"
                fullWidth
                startIcon={<CalendarMonth />}
                endIcon={
                  schedule ? (
                    <Close
                      sx={{
                        width: '15px',
                        height: '15px',
                        '&:hover': { color: '#292929' },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlers?.handleDateChange(null);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
                disabled={[
                  PostLifecycleState.Scheduled,
                  PostLifecycleState.Posted,
                ].includes(post?.lifecycleState!)}
                variant="outlined"
                onClick={handleOpenDatepicker}
              >
                {schedule ? formatDate(schedule) : 'Tentative Date'}
              </Button>
            </Stack>
          )}

          {post?.type === PostType.AdvocacyParent && (
            <>
              <Grid item xs={12} data-name="AdvocacyParentGrid">
                <Typography data-name="AdvocacyParentTypography" marginBottom={1}>
                  {t('Assign a Magnet Qty to track its impact')}
                </Typography>
                {handlers && (
                  <MagnetsSelector
                    data-name="MagnetsSelector"
                    magnets={advocacyPostInput?.magnets!}
                    onChange={handlers.handleMagnetsChange}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ paddingTop: 0 }} data-name="MessageGrid">
            {displayMessage && (
              <Alert
                data-name="MessageAlert"
                sx={{
                  alignItems: 'center',
                  p: 1,
                  width: 'fit-content',
                  backgroundColor: 'inherit',
                  flexDirection: { xs: 'column', sm: 'row' },
                  '& .MuiAlert-action': {
                    margin: 0,
                    padding: 0,
                  },
                }}
                severity={displayMessage?.type}
                {...(displayMessage.action
                  ? {
                      action: (
                        <Button
                          size="small"
                          onClick={displayMessage.action.onClick}
                          sx={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            color: 'text.primary',
                          }}
                        >
                          {displayMessage.action.text}
                        </Button>
                      ),
                    }
                  : {})}
              >
                {displayMessage?.text}
              </Alert>
            )}
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid item xs={12} md={7} data-name="PostOptionsGrid">
            <Stack data-name="PostOptionsStack" spacing={2}>
              <Box
                data-name="CloseButtonBox"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <IconButton
                  data-name="CloseButton"
                  onClick={() => onClose()}
                  sx={{
                    backgroundColor: '#fff', // Optional styling
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Box>

              <Box data-name="PostOptionsBox">
                <PostOptions data-name="PostOptions" brand={post?.brand} />
              </Box>
            </Stack>
          </Grid>
        )}
      </Stack>
      <Grid
        item
        xs={12}
        data-name="PostActionsGrid"
        justifyContent="flex-end"
        style={{
          paddingTop: 10,
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 1,
          borderTop: '1px solid #e0e0e0', // Added border top
        }}
        height={50}
      >
        <PostActions
          data-name="PostActions"
          setError={setMessage}
          onClose={onClose}
          openChat={openChat}
          isLink={isLink}
          isLinkInbox={isLinkInbox}
        />
      </Grid>
      <TagModal
        data-name="TagModal"
        openTagModal={openTagModal}
        setOpenTagModal={setOpenTagModal}
        handleAddTags={handlers!.handleAddTags}
        tags={post?.tags as Tag[]}
        shellId={post?.shellId}
      />
    </PostAdvocacyMembersProvider>
  );
};

export default PostEditor;
