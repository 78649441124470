import { gql } from '../__generated__/gql';

export const UPDATE_FAVORITE_POST = gql(`
  mutation UpdateFavoritePost($postId: ObjectId!, $favorite: Boolean) {
    updateFavoritePost(postId: $postId, favorite: $favorite) {
      success
      post {
        _id
        favorite
      }
    }
  }
`);

export const UPDATE_CONTENT_POST = gql(`
  mutation UpdateContentPost($postId: ObjectId!, $media: [MediaPostInput!], $html: String, $json: Any, $tags: [TagInput], $thumbnail: ThumbnailInput) {
    updateContentPost(postId: $postId, media: $media, html: $html, json: $json, tags: $tags, thumbnail: $thumbnail) {
      success
      post {
        _id
        content {
          body
          json
          media {
            storagePath
            type
            url
            alt
          }
          thumbnail {
          source
          title
          description
          media {
          type
          url
          alt         
           }
          }
        }
      }
    }
  }
`);

export const UPDATE_TAGS = gql(`
  mutation UpdateTags($postId: ObjectId, $tags: [TagInput]) {
    updateTags(postId: $postId, tags: $tags) {
      success
      post {
        _id
        tags {
          _id
          name
          color
        }
    }
}
  }
`);

export const CREATE_REAL_STATE_DESCRIPTION = gql(`
  mutation CreateRealEstateDescription($input: RealEstateDescriptionInput) {
    createRealEstateDescription(input: $input) {
      success
      message
    }
  }
`);

export const CREATE_JOB_DESCRIPTION = gql(`
  mutation CreateJobDescription($input: JobDescriptionInput) {
    createJobDescription(input: $input) {
      success
      message
    }
  }
`);

export const CREATE_THEME_DESCRIPTION = gql(`
  mutation CreateThemeDescription($input: String) {
    createThemeDescription(input: $input) {
      success
      message
      data
      
    }
  }
`);

export const CREATE_JOB_DESCRIPTION_ISPROX = gql(`
  mutation CreateJobDescriptionIsprox($input: JobDescriptionIsproxInput) {
    createJobDescriptionIsprox(input: $input) {
      success
      message
      data
    }
  }
`);

export const UPDATE_SCHEDULE_POST = gql(`
  mutation UpdateSchedulePost($postId: ObjectId!, $schedule: Date) {
    updateSchedulePost(postId: $postId, schedule: $schedule) {
      success
      message
      post {
        _id
        schedule
        lifecycleState
        lifecycleStateDisplay {
          name
          color
          order
          isPostPostable
          isStateChangeable
        }
      }
    }
  }
`);

export const DELETE_POST = gql(`
  mutation DeletePost($postId: ObjectId!, $feedback: DeletePostFeedbackInput) {
    deletePost(postId: $postId, feedback: $feedback) {
      success
      post {
        _id
        lifecycleState
      }
    }
  }
`);

export const HARD_DELETE_POST = gql(`
  mutation HardDeletePost($postId: ObjectId!) {
    hardDeletePost(postId: $postId) {
      success
      post {
        _id
      }
    }
  }
`);

export const HARD_DELETE_POSTS = gql(`
  mutation HardDeletePosts($postIds: [ObjectId!]!) {
    hardDeletePosts(postIds: $postIds) {
      success
      message
    }
  }
`);

export const CREATE_USER = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      message
      user {
        _id
        email
        firstName
        lastName
        role
        plan
        avatar
        createdOn
        position
        shellName
        shellId
        firstLogin
        lifecycleState
      }
    }
  }
`);

export const INVITE_USER = gql(`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      success
      message
      user {
        _id
        email
        firstName
        lastName
        role
        plan
        avatar
        createdOn
        position
        shellName
        shellId
        firstLogin
        lifecycleState
      }
    }
  }
`);

export const INVITE_USERS = gql(`
  mutation InviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      success
      message
      emailsExist
    }
  }
`);

export const USER_SIGNUP = gql(`
  mutation UserSignUp($input: UserSignUpInput!) {
    userSignUp(input: $input) {
      success
      message
      user {
        _id
        brandId
        shellId
        shellName
        shell {
          _id
          logo
          name
          title
          icon
          whiteLabel
        }
        email
        firstName
        lastName
        role
        plan
        position
        avatar
        numMagnets
        firstLogin
        lifecycleState
        notifications {
          email
        }
        customMenus {
          title
          url
          icon
        }
        trialEnd
        activeTrial
        isActive
        brandSelectedId
        brandSelected {
          _id
          typeBrand
          name
          shellId
          shell {
            _id
            name
          }
          backgroundColor
          account {
            id
            type
            name
            token
            avatar
          }
          lastMetricsUpdate
        }
        stripeCustomerId
        brand {
          _id
          name
          shellId
          email
          typeBrand
          phone
          backgroundColor
          userId
          companyBrandId
          approval {
            required
            email
            whatsapp
          }
          account {
            id
            type
            name
            token
            exp
            avatar
          }
          contentCreation {
            language
            bio
            hashtags
            signature
            toneVoice
            negatives
            otherIndustries
            industry
            subIndustry
            competitors
            competitiveAdvantage
            position
            themeTalk
            targetBrand
            targetPublic
            goalsBrand
            otherGoalsBrand
            valuesBrand
            corporateValues
            requireContent
            responsabilities
            perspective
            numberEmojis
            numberHashtags
            textLength
            themeExtra
            uniqueBrand
            themeNegative
            corporatePostFirstPart
            corporatePostSecondPart
            corporatePostThirdPart
            otherCorporatePostFirstPart
            otherCorporatePostSecondPart
            otherCorporatePostThirdPart
            contentConsumption
            otherContentConsumption
            firstPost
            secondPost
            thirdPost
            fourthPost
            worldLeaderInspiration
            industryLeaderInspiration
            hobbies
            artistsInspiration
            valuesBrand
            corporateValues
            politicalSocialActivityBrand
            trendingTopics
            trustedMedia
            corporateTarget
            corporateIdeas
            corporateVoice
          }
        
        }
      }
    }
  }
`);

export const UPDATE_USER = gql(`
  mutation UpdateUser($userId: ObjectId!, $input: UpdateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      success
      message
      user {
        _id
        email
        firstName
        lastName
        role
        plan
        avatar
        createdOn
        deleted
        position
        shellId
        shellName
      }
    }
  }
`);

export const UPDATE_ONBOARDING_INFO = gql(`
  mutation UpdateUserOnboardingInfo($userId: ObjectId!, $input: UpdateOnboardingInfoInput!) {
    updateUserOnboardingInfo(userId: $userId, input: $input) {
      success
      message
      user {
        _id
        email
        firstName
        lastName
        role
        plan
        avatar
        createdOn
        deleted
        lifecycleState
        position
        shellId
        shellName
        firstLogin
      }
    }
  }
`);

export const ADD_SOCIAL_MEDIA_ACCOUNT = gql(`
  mutation AddSocialMediaAccount($input: AddSocialMediaAccountInput!) {
    addSocialMediaAccount(input: $input) {
      success
      message
      account {
        id
        type
        token
        name
        exp
      }
    }
  }
`);

export const REMOVE_SOCIAL_MEDIA_ACCOUNT = gql(`
  mutation RemoveSocialMediaAccount($brandId: ObjectId!) {
    removeSocialMediaAccount(brandId: $brandId) {
      success
      message
    }
  }
`);

export const UPDATE_USER_AVATAR = gql(`
  mutation UpdateUserAvatar($avatar: String) {
    updateUserAvatar(avatar: $avatar) {
      success
      message
      user {
        _id
        avatar
      }
    }
  }
`);

export const CREATE_POST = gql(`
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      success
      message
      post {
        _id
        shellId
        createdOn
        publishedOn
        lifecycleState
        title
        lifecycleStateDisplay { 
          name
          color
          order
          isStateChangeable
          isPostPostable
        }
        type
        brand {
          _id
          name
          shellId
          backgroundColor
          account {
            avatar
            id
            name
            token
            exp
            type
          }
          userId
          user {
            _id
            role
            shellId
            shellName
            firstName
            lastName
            email
          }
        }
        tags {
          _id
          name
          color
        }
        schedule
        content {
          title
          body
          json
          preview
          hashtags
          media {
            type
            url
            alt
          }
        }
        magnets
      }
    }
  }
`);

export const UPSERT_INSIGHT = gql(`
  mutation UpsertInsight($input: UpsertInsightInput) {
    upsertInsight(input: $input) {
      success
      message
      insight {
        _id
        title
        link
        creator
        description
        content
        pubDate
        imgUrl
        videoUrl
        source
        articleId
        keywords
        type
        sentiment
        favorite
        media {
          type
          url
          alt
        }
        author {
          description
          firstName
          lastName
          fullName
          profilePicture
        }
        interactions {
          comments
          numLikes
        }
      }
    }
  }
`);

export const CREATE_ADVOCACY_POST = gql(`
  mutation CreateAdvocacyPost($input: AdvocacyPostInput!) {
    createAdvocacyPost(input: $input) {
      success
      message
      post {
        _id
        createdOn
        publishedOn
        content {
          title
          body
          preview
          hashtags
          media {
            type
            url
            alt
          }
        }
        magnets
      }
    }
  }
`);

export const GENERATE_IDEA_FOR_POST = gql(`
  mutation GenerateIdeaForPost($brandId: ObjectId!) {
    generateIdeaForPost(brandId: $brandId) {
      success
      message
      postIdea
    }
  }
`);

export const GENERATE_CONTENT_AI = gql(`
  mutation GenerateContentAI($brandId: ObjectId!, $contentIdea: String!, $longContent: Boolean) {
    generateContentAI(brandId: $brandId, contentIdea: $contentIdea, longContent: $longContent) {
      success
      message
    }
  }
`);

export const UPDATE_ADVOCACY_POST = gql(`
  mutation UpdateAdvocacyPost($postId: ObjectId!, $input: AdvocacyPostInput!) {
    updateAdvocacyPost(postId: $postId, input: $input) {
      success
      message
      post {
        _id
        createdOn
      }
    }
  }
`);

export const DELETE_ADVOCACY_POST = gql(`
  mutation DeleteAdvocacyPost($postId: ObjectId!) {
    deleteAdvocacyPost(postId: $postId) {
      success
      message
      post {
        _id
        lifecycleState
      }
    }
  }
`);

export const UPDATE_POST = gql(`
  mutation UpdatePost($postId: ObjectId!, $input: UpdatePostInput!) {
    updatePost(postId: $postId, input: $input) {
      success
      message
      post {
        _id
        createdOn
        publishedOn
        lifecycleState
        schedule
        type
        tags {
        _id
        name
        color
        }
        shellId
        shellName
        content {
          title
          body
          preview
          hashtags
          media {
            type
            url
            alt
          }
        }
        advocacy {
          editable
          parentPostId
          parentPost {
            _id
            createdOn
            lifecycleState
            type
            content {
              preview
              body
              media {
                type
                url
                alt
              }
            }
          }
        }
        magnets
      }
    }
  }
`);

export const GENERATE_POST = gql(`
  mutation GeneratePost($runId: ObjectId!) {
    generatePost(runId: $runId) {
      success
      message
      run {
        _id
      }
    }
  }
`);

export const DELETE_GROUP = gql(`
  mutation DeleteGroup($groupId: ObjectId) {
    deleteGroup(groupId: $groupId) {
      success
      message
    }
  }
`);

export const CREATE_GROUP_BY_USER = gql(`
  mutation CreateGroup($input: CreateGroupInput) {
  createGroup(input: $input) {
    success
    message
    group {
      _id
      name
      shellId
    }
  }
}
`);

export const UPDATE_GROUP_BY_USER = gql(`
mutation UpdateGroupName($input: UpdateGroupInput!) {
  updateGroup(input: $input) {
    success
    message
    group {
      _id
      name
    }
  }
}`);

export const CREATE_CHAIN = gql(`
  mutation CreateChain($input: CreateChainInput!) {
    createChain(input: $input) {
      success
      message
    }
  }
`);

export const UPDATE_CHAIN = gql(`
  mutation UpdateChain($chainId: ObjectId!, $input: UpdateChainInput!) {
    updateChain(chainId: $chainId, input: $input) {
      success
      message
    }
  }
`);

export const SELECT_WINNER_CHAIN = gql(`
  mutation SelectWinnerChain($runId: ObjectId!, $winner: PromptWinner!) {
    selectWinnerChain(runId: $runId, winner: $winner) {
      success
      message
    }
  }
`);

export const CREATE_RUN_ASYNC = gql(`
  mutation CreateRunAsync($input: PrompterRunInput!) {
    createRunAsync(input: $input) {
      success
      message
      run {
        _id
        brandId
        brandName
        chainId
        lifecycleState
      }
    }
  }
`);

export const CREATE_RUN_IN_BATCH = gql(`
  mutation CreateRunInBatch($input: [PrompterRunInput!]!) {
    createRunInBatch(input: $input) {
      success
      message
      run {
        _id
        brandId
        brandName
        chainId
        lifecycleState
      }
    }
  }
`);

export const UPDATE_RUN = gql(`
  mutation UpdateRun($runId: ObjectId!, $input: PrompterRunInput!, $execute: Boolean) {
    updateRun(runId: $runId, input: $input, execute: $execute) {
      success
      message
      run {
        _id
      }
    }
  }
`);

export const DELETE_RUN = gql(`
  mutation DeleteRun($runId: ObjectId!) {
    deleteRun(runId: $runId) {
      success
      message
      run {
        _id
      }
    }
  }
`);

export const DELETE_USERS_WITH_CYPRESS = gql(`
  mutation DeleteWithCypress {
    deleteUsersWithCypress {
      success
      message
    }
  }
`);

export const DELETE_COMPANIES_WITH_CYPRESS = gql(`
  mutation DeleteCompaniesWithCypress {
    deleteCompaniesWithCypress {
      success
      message
    }
  }
`);

export const DELETE_POSTS_WITH_CYPRESS = gql(`
  mutation DeletePostsWithCypress {
    deletePostsWithCypress {
      success
      message
    }
  }
`);

export const CREATE_SHELL = gql(`
  mutation CreateShell($input: ShellInput) {
  createShell(input: $input) {
    success
    message
    shell {
      _id
      avatar
      logo
      name
      noSendEmail
      plans {
        advocacy
        advanced
        corporate
      }
    }
  }
}
`);

export const UPDATE_SHELL = gql(`
mutation UpdateShell($input: ShellInput, $shellId: ObjectId) {
  updateShell(input: $input, shellId: $shellId) {
    success
    message
    shell {
      _id
      avatar
      logo
      icon
      title
      whiteLabel
      noSendEmail
      plans {
        advocacy
        advanced
        corporate
      }
      name
    }
  }
}
`);

export const UNSUBSCRIBE_USER = gql(`
mutation UnsubscribeUser($userId: ObjectId!, $type: NotificationType!, $notificationMethod: NotificationMethod!) {
  unsubscribeUserNotifications(userId: $userId, type: $type, notificationMethod: $notificationMethod) {
    success
    message
  }
}
`);

export const SUBSCRIBE_USER = gql(`
mutation SubscribeUser($userId: ObjectId!, $type: NotificationType!, $notificationMethod: NotificationMethod!) {
  subscribeUserNotifications(userId: $userId, type: $type, notificationMethod: $notificationMethod) {
    success
    message
  }
}
`);

export const SEND_INVITE = gql(`
  mutation SendInvite($userId: ObjectId!, $brandManager: Boolean) {
    sendInvite(userId: $userId, brandManager: $brandManager) {
      success
      message
    }
  }
`);

export const REQUEST_UPGRADE = gql(`
  mutation RequestUpgrade {
    requestUpgrade {
      success
      message
    }
  }
`);

export const UPDATE_FAVORITE_INSIGHT = gql(`
  mutation UpdateFavoriteInsight($insightId: ObjectId!, $favorite: Boolean) {
    updateFavoriteInsight(insightId: $insightId, favorite: $favorite) {
      success
      insight {
        _id
        favorite
      }
    }
  }
`);

export const UPDATE_SENTIMENT_INSIGHT = gql(`
  mutation UpdateSentimentInsight($insightId: ObjectId!, $sentiment: Sentiment) {
    updateSentimentInsight(insightId: $insightId, sentiment: $sentiment) {
      success
      insight {
        _id
        sentiment
      }
    }
  }
`);

export const UPDATE_BRAND_INSIGHTS_TOPICS = gql(`
  mutation UpdateBrandInsightsTopics($brandId: ObjectId!, $topics: [String!]) {
    updateBrandInsightsTopics(brandId: $brandId, topics: $topics) {
      success
      message
    }
  }
`);

export const UPDATE_NOTIFICATION_MESSAGE = gql(`
  mutation UpdateNotificationMessage($input: MessagesInput!) {
    updateNotificationMessage(input: $input) {
      success
      message
    }
  }
`);

export const GET_VIDEO_URL = gql(`
mutation GetVideoUrl($url: String) {
  getVideoUrl(url: $url) {
    videoUrl
    success
  }
}
`);

export const GET_STRIPE_CUSTOMER_PORTAL_SESSION = gql(`
  mutation GetStripeCustomerPortalSession {
    getStripeCustomerPortalSession {
      sessionUrl
      success
      message
    }
  }
`);

export const GET_STRIPE_CHECKOUT_SESSION = gql(`
  mutation GetStripeCheckoutSession($plans: [UserPlan], $adjustableQuantity: Boolean, $billingPeriod: BillingPeriod $successUrl: String) {
    getStripeCheckoutSession(plans: $plans, adjustableQuantity: $adjustableQuantity, billingPeriod: $billingPeriod, successUrl: $successUrl) {
      sessionUrl
      success
      message
    }
  }
`);

export const CHANGE_BRAND_SELECTED_ID = gql(`
mutation ChangeBrandSelectedId($brandSelectedId: ObjectId!) {
  changeBrandSelectedId(brandSelectedId: $brandSelectedId) {
    success
    message
    user {
      _id
      brandSelectedId
      brandSelected {
        _id
        typeBrand
        name
        shellId
        shell {
          _id
          name
        }
        backgroundColor
        account {
          id
          type
          name
          token
          avatar
        }
        contentCreation {
          requireContent
        }
        lastMetricsUpdate
      }
    }
  }
}

`);

export const CHAT_MESSAGES_READ = gql(`
  mutation ChatMessagesRead($postId: ObjectId!, $userId: ObjectId!) {
    chatMessagesRead(postId: $postId, userId: $userId) {
      success
    }
  }
`);

export const UPDATE_POST_STATE = gql(`
  mutation UpdatePostLifecycleState($postId: ObjectId!, $lifecycleState: PostLifecycleState!) {
    updatePostLifecycleState(postId: $postId, lifecycleState: $lifecycleState) {
      success
      message
      post {
        _id
        lifecycleState
        lifecycleStateDisplay {
          name
          color
          order
          isStateChangeable
          isPostPostable
        }
      }
    }
  }
`);

export const UPDATE_POST_DATE = gql(`
  mutation UpdatePostDate($postId: ObjectId!, $date: Date) {
    updatePostDate(postId: $postId, date: $date) {
      success
      message
      post {
        _id
        schedule
      }
    }
  }
`);

export const ENABLE_SHARE_LINK = gql(`
  mutation enableShareLink($postId: ObjectId!) {
    enableShareLink(postId: $postId) {
      success
      message
    }
  }
`);

export const ENABLE_SHARE_LINK_INBOX = gql(`
  mutation enableShareLinkInbox($brandId: ObjectId!, $password: String) {
    enableShareLinkInbox(brandId: $brandId, password: $password) {
      success
      message
    }
  }
`);

export const VALIDATE_BRAND_PASSWORD_INBOX = gql(`
  mutation validateBrandPasswordInbox($brandId: ObjectId!, $password: String) {
    validateBrandPasswordInbox(brandId: $brandId, password: $password) {
      success
      message
    }
  }
`);

export const CREATE_BRAND = gql(`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      success
      message
      brand {
        _id
        name
        shellId
        shell {
          _id
          name
        }
        email
        typeBrand
        createdOn
        phone
        userId
        user {
          _id
          email
          firstName
          role
          shellId
          shellName
          notifications {
            email
          }
        }
        groupsIds
        groups {
          _id
          name
          shellId
        }
        backgroundColor
        companyBrandId
        approval {
          required
          email
          whatsapp
        }
        account {
          id
          type
          name
          token
          exp
          avatar
        }
        contentCreation {
          language
          bio
          hashtags
          signature
          toneVoice
          negatives
          otherIndustries
          industry
          subIndustry
          competitors
          competitiveAdvantage
          position
          themeTalk
          targetBrand
          targetPublic
          goalsBrand
          otherGoalsBrand
          valuesBrand
          corporateValues
          requireContent
          responsabilities
          perspective
          numberEmojis
          numberHashtags
          textLength
          themeExtra
          uniqueBrand
          themeNegative
          corporatePostFirstPart
          corporatePostSecondPart
          corporatePostThirdPart
          otherCorporatePostFirstPart
          otherCorporatePostSecondPart
          otherCorporatePostThirdPart
          contentConsumption
          otherContentConsumption
          firstPost
          secondPost
          thirdPost
          fourthPost
          worldLeaderInspiration
          industryLeaderInspiration
          hobbies
          artistsInspiration
          valuesBrand
          corporateValues
          politicalSocialActivityBrand
          trendingTopics
          trustedMedia
          corporateTarget
          corporateIdeas
          corporateVoice
        }
      }
    }
  }
`);

export const UPDATE_BRAND = gql(`
  mutation UpdateBrand($brandId: ObjectId!, $input: UpdateBrandInput!) {
    updateBrand(brandId: $brandId, input: $input) {
      success
      message
    }
  }
`);

export const DELETE_BRAND = gql(`
  mutation DeleteBrand($brandId: ObjectId!) {
    deleteBrand(brandId: $brandId) {
      success
      message
    }
  }


`);

export const INACTIVE_USER = gql(`
mutation InactiveUser($userId: ObjectId!) {
  inactiveUser(userId: $userId) {
    success
    message
  }
}
`);

export const ADD_BRANDS_IN_GROUP = gql(`
mutation AddBrandsInGroup($brandIds: [ObjectId]!, $groupId: ObjectId!) {
  addBrandsInGroup(brandIds: $brandIds, groupId: $groupId) {
    success
    message
  }
}
`);

export const REMOVE_BRANDS_FROM_GROUP = gql(`
mutation RemoveBrandsFromGroup($brandId: ObjectId!, $groupId: ObjectId!) {
  removeBrandsFromGroup(brandId: $brandId, groupId: $groupId) {
    success
    message
  }
}
`);

export const UPDATE_MEMBER = gql(`
mutation UpdateMember($userId: ObjectId!, $role: UserRole, $brands: [ObjectId]) {
  updateMember(userId: $userId, role: $role, brands: $brands) {
    success
    message
  }
}
`);

export const DELETE_BRAND_MANAGER_FROM_BRAND = gql(`
mutation DeleteBrandManagerFromBrand($userId: ObjectId!, $brandId: ObjectId!) {
  deleteBrandManagerFromBrand(userId: $userId, brandId: $brandId) {
    success
    message
  }
}
`);

export const ADD_BRANDS_MANAGER_TO_BRAND = gql(`
mutation AddBrandsManagerToBrand($brandId: ObjectId!, $userIds: [ObjectId!]) {
  addBrandsManagerToBrand(brandId: $brandId, userIds: $userIds) {
    success
    message
  }
}
`);

export const CREATE_IDEAS = gql(`
  mutation CreateIdeas($input: [CreateIdeaInput!]!) {
    createIdeas(input: $input) {
      success
      message
      ideas {
        _id
        content
        chainId
        chainName
        brandId
        brandName
        priority
      }
    }
  }
`);

export const UPDATE_IDEA = gql(`
  mutation UpdateIdea($ideaId: ObjectId!, $input: UpdateIdeaInput!) {
    updateIdea(ideaId: $ideaId, input: $input) {
      success
      message
      idea {
        _id
        content
        chainId
        chainName
        brandId
        brandName
        priority
        shellId
      }
    }
  }
`);

export const DELETE_IDEA = gql(`
  mutation DeleteIdea($ideaId: ObjectId!) {
    deleteIdea(ideaId: $ideaId) {
      success
      message
    }
  }
`);

export const GENERATE_IDEAS = gql(`
  mutation GenerateIdeas($topicContext: String, $brandId: ObjectId, $chainId: ObjectId) {
    generateIdeas(topicContext: $topicContext, brandId: $brandId, chainId: $chainId) {
      success
      message
      ideas
    }
  }
`);

export const CHANGE_ROLE = gql(`
  mutation ChangeRole($userId: ObjectId!, $role: [UserRole]!) {
    changeRole(userId: $userId, role: $role) {
      success
      message
    }
  }
`);

export const EXTRACT_THUMBNAIL_DATA = gql(`
  mutation ExtractThumbnailData($url: String!) {
    extractThumbnailData(url: $url) {
      title
      description
      image
      imageBlob
    }
  }
`);

export const ADD_TAG_TO_SHELL = gql(`
  mutation AddTagToShell($tag: TagInput!, $shellId: ObjectId) {
    addTagToShell(tag: $tag, shellId: $shellId) {
      success
      message
      tag {
        _id
        name
        color
      }
    }
  }
`);

export const CHANGE_POST_TITLE = gql(`
  mutation ChangePostTitle($postId: ObjectId!, $title: String!) {
    changePostTitle(postId: $postId, title: $title) {
      success
      message
    }
  }
`);

export const SELECT_IS_IDEA_POST = gql(`
  mutation SelectIsIdeaPost($postId: ObjectId!, $isIdea: Boolean!) {
    selectIsIdeaPost(postId: $postId, isIdea: $isIdea) {
      success
      message
    }
  }
`);

export const CREATE_USER_SELF_SERVICE = gql(`
  mutation CreateUserSelfService($input: CreateUserSelfServiceInput!) {
    createUserSelfService(input: $input) {
      success
      message
      user {
        _id
        email
        firstName
        lastName
        role
        plan
        avatar
        createdOn
        position
        shellName
        shellId
        firstLogin
        lifecycleState
      }
    }
  }
`);

export const CREATE_BRAND_SELF_SERVICE = gql(`
  mutation CreateBrandSelfService($input: CreateBrandSelfServiceInput!) {
    createBrandSelfService(input: $input) {
      success
      message
}
}
`);

export const ACTIVE_TRIAL_USER = gql(`
  mutation ActiveTrialUser($billingPeriod: BillingPeriod!) {
    activeTrialUser(billingPeriod: $billingPeriod) {
      success
      message
    }
  }
`);

export const HIDE_VIDEO_TUTORIAL = gql(`
  mutation HideVideoTutorial {
    hideVideoTutorial {
      success
      message
    }
  }
`);

export const ADD_URLPROFILE_TO_BRAND = gql(`
  mutation AddUrlProfileToBrand($urlProfile: String!, $brandId: ObjectId!) {
    addUrlProfileToBrand(urlProfile: $urlProfile, brandId: $brandId) {
      success
      message
    }
  }
`);

export const GENERATE_LINK_INVITATION = gql(`
  mutation GenerateLinkInvite($userId: ObjectId!) {
    generateLinkInvite(userId: $userId) {
      success
      message
      inviteLink
    }
  }
`);

export const SET_POST_VISIBILITY = gql(`
  mutation SetPostVisibility($postId: ObjectId!, $isHidden: Boolean!) {
    setPostVisibility(postId: $postId, isHidden: $isHidden) {
      success
      message
      visibility
    }
  }
`);

export const SET_READ_APP_NOTIFICATION = gql(`
  mutation SetReadAppNotification($messageId: ObjectId!) {
    setReadAppNotification(messageId: $messageId) {
      success
      message
    }
  }
`);

export const DOWNLOAD_DATA_REQUEST = gql(`
  mutation DownloadDataRequest($userId: ObjectId!, $shellId: ObjectId!, $userEmail: String!) {
    downloadDataRequest(userId: $userId, shellId: $shellId, userEmail: $userEmail) {
      success
      message
    }
  }
`);
