import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { useFormContext } from '../../contexts/FormContext';
import { propertyOptions } from './consts';
import { RealEstateDescriptionInput } from '../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

const Step1 = ({
  onSubmit,
  defaultValues,
}: {
  onSubmit: (data: RealEstateDescriptionInput) => void;
  defaultValues: RealEstateDescriptionInput;
}) => {
  const { t } = useTranslation();
  const { formData } = useFormContext();
  const { control, handleSubmit, formState } = useForm<RealEstateDescriptionInput>({
    defaultValues: {
      ...defaultValues,
      ...formData,
    },
  });

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <TextField
        control={control}
        name="propertyCompany"
        placeholder={t('Ej:  Engel & Völkers')}
        label={t(
          '¿Para qué empresa estarías generando esta oferta de alquiler o venta de propiedades?',
        )}
      />
      <TextField
        control={control}
        name="propertyName"
        required={true}
        placeholder={t(
          'Ej: Increíble piso céntrico en Barcelona, recientemente reformado',
        )}
        label={t('¿Qué título le pondrías a la oferta de la propiedad?')}
      />
      <Select
        options={propertyOptions.map(attr => ({ label: t(attr.label), value: attr.value }))}
        control={control}
        name="propertyState"
        required
        placeholder={t('Ej: Alquiler')}
        label={t('¿Es para alquiler o para venta?')}
      />
      <TextField
        control={control}
        name="propertySize"
        required={true}
        type="number"
        placeholder={t('Ej: 300 m²')}
        label={t('¿Cuántos metros cuadrados tiene la propiedad?')}
      />
      <TextField
        control={control}
        multiline={true}
        name="propertyDescription"
        required={true}
        placeholder={t(
          'Ejemplo: Piso recientemente reformado, con buena entrada de luz, amueblado y listo para ser arrendado, piso de parquet, con sala y comedor amplios.',
        )}
        label={t('¿Podrías proporcionar una breve descripción de la propiedad?')}
      />
      <FormActions
        isValid={formState.isValid}
        firstStep
        buttonId="lm-real-estate-description-step-1-button"
      />
    </Stack>
  );
};

export default Step1;
