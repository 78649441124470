import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InputLabel = ({
  label,
  required,
  colorRequired = 'primary.main',
}: {
  label: string;
  required?: boolean;
  colorRequired?: string;
}) => {
  const stylesFont = {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 900,
    '@media (min-width:600px)': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 900,
    },
  };
  const { t } = useTranslation();

  return (
    <Stack flexDirection={'row'}>
      <Typography sx={stylesFont}>
        {label}
        {required ? (
          <Typography
            color={colorRequired}
            fontSize={'25px'}
            lineHeight={'20px'}
            component={'span'}
          >
            {' '}
            *
          </Typography>
        ) : (
          <Typography fontSize={16} component={'span'}>
            {' '}
            {t('(Opcional)')}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
};

export default InputLabel;
