import { useMutation } from '@apollo/client';
import {
  AutoAwesome,
  EditOutlined,
  PlaylistAdd,
  ReadMore,
  Translate as TranslateIcon,
  Spellcheck as SpellcheckIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
  Box,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import AIAssistantModal from './AIAssistantModal';
import { AuthContext } from '../../context/AuthContext';
import { PostType, Tag, UserRole } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GENERATE_IDEA_FOR_POST } from '../../graphql/mutations';
import LoaderGif from '../../assets/spinner.gif';

export const AIAssistantActions: Tag[] = [
  {
    name: 'GENERATE_IDEA',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'GENERATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'GET_INSPIRATION',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'COMPLETE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'REWRITE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'ASK_MAG',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'TRANSLATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'CORRECT_GRAMMAR_SPELL',
    color: '#FFFFFF',
    internal: true,
  },
];

interface Props {
  postId?: string;
  editor: Editor;
  onLoading: (loading: boolean) => void;
  onChange?: (content: string, json: any, action?: Tag) => void;
}

const AIAssistantMenu = ({ postId, editor, onChange, onLoading = () => {} }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);

  const [aiAssistOpen, setAiAssistOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIdeaPost, setLoadingGenerateIdeaPost] = useState<boolean>(false);
  const [anchorElAiMenu, setAnchorElAiMenu] = useState<HTMLButtonElement | null>(null);
  const [translateMenuAnchorEl, setTranslateMenuAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const cursorPositionRef = useRef<number>(editor.state.selection.anchor);

  const menuOpen = Boolean(anchorElAiMenu);
  const translateMenuOpen = Boolean(translateMenuAnchorEl);
  const content = editor.getText({ blockSeparator: '\n' });

  const [generateIdeaForPost] = useMutation(GENERATE_IDEA_FOR_POST);

  const handleGenerateIdea = async () => {
    try {
      setLoadingGenerateIdeaPost(true);
      onLoading(true);

      editor.setOptions({ editable: false });

      const { data } = await generateIdeaForPost({
        variables: {
          brandId:
            postState?.post.brandId ||
            postState?.postRef.current.brand?._id ||
            postState?.postRef.current.brandId ||
            '', // Adjust based on your mutation variables
        },
      });

      if (data?.generateIdeaForPost?.success) {
        const postIdea = data.generateIdeaForPost.postIdea;

        if (postIdea) {
          editor.commands.insertContentAt(
            0,
            '<p>' + postIdea.replaceAll('\n', '<br>') + '</p>',
          );
          onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[0]);
        }
      } else {
        setErrorMessage(
          data?.generateIdeaForPost?.message || t('Failed to generate idea'),
        );
      }
    } catch (error) {
      setErrorMessage(t('An error occurred while generating the idea'));
    } finally {
      editor.setOptions({ editable: true });
      setLoadingGenerateIdeaPost(false);
      onLoading(false);
    }
  };

  const [fetchDataCompleteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[3]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataRewriteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[4]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataTranslateContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[6]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataCorrectGrammarSpell] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[7]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const handleCompleteContent = async () => {
    onLoading(true);
    setLoading(true);
    editor.commands.focus('end');
    cursorPositionRef.current = editor.state.selection.anchor;
    fetchDataCompleteContent({
      input: content,
      chainId: '66310b7bf1de1c64651db3a1',
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
    });
  };

  const handleRewriteContent = async () => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataRewriteContent({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId: '66310b98f1de1c64651db3a3',
      userId: user?._id || undefined,
    });
  };

  const handleTranslateContent = async (language: string) => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');

    let chainId = '';
    switch (language) {
      case 'en':
        chainId = '66fa5e131dc63fe14d6b8e74';
        break;
      case 'es':
        chainId = '66fa872c1dc63fe14d6b8e76';
        break;
      case 'pt-br':
        chainId = '6718cd72089cdf1d432bfc73';
        break;
      case 'pt-pt':
        chainId = '6718ce04089cdf1d432bfc74';
        break;
      case 'fr':
        chainId = '6718ce5c089cdf1d432bfc75';
        break;
      case 'de':
        chainId = '6718ceb0089cdf1d432bfc76';
        break;
      case 'it':
        chainId = '6718cf05089cdf1d432bfc77';
        break;
      case 'es-latam':
        chainId = '6718cf5a089cdf1d432bfc78';
        break;
      case 'pl':
        chainId = '6718e61a089cdf1d432c046c';
        break;
      default:
        chainId = '';
        break;
    }

    fetchDataTranslateContent({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId,
      userId: user?._id || undefined,
    });
  };

  const handleCorrectGrammarSpell = async () => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataCorrectGrammarSpell({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId: '66fa72dc1dc63fe14d6b8e75',
      userId: user?._id || undefined,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    editor.setOptions({
      editable: false,
    });

    setAnchorElAiMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElAiMenu(null);

    editor.setOptions({
      editable: true,
    });
  };

  const handleTranslateMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTranslateMenuAnchorEl(event.currentTarget);
  };

  const handleTranslateMenuClose = () => {
    setTranslateMenuAnchorEl(null);
  };

  const text = {
    fontSize: '14px',
  };

  return (
    <>
      {loadingIdeaPost && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent overlay
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            flexDirection: 'column',
          }}
        >
          <img
            src={LoaderGif}
            alt="Loading"
            style={{
              width: '300px', // Adjust based on your needs
              height: '100px',
              objectFit: 'contain',
            }}
          />
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('Give us a moment to work our magic.')}
          </Typography>
        </Box>
      )}
      <AIAssistantModal
        open={aiAssistOpen}
        onClose={() => setAiAssistOpen(false)}
        onAddText={(content) => {
          editor.commands.insertContent(
            '<p>' + content.replaceAll('\n', '<br>') + '</p>',
          );
          editor.setEditable(true);
          onChange && onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[1]);
        }}
      />
      {isMobile ? (
        <IconButton
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <AutoAwesome />
        </IconButton>
      ) : (
        <LoadingButton
          variant="outlined"
          sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
          startIcon={<AutoAwesome />}
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
          loadingPosition="start"
          loading={loading}
        >
          {t('Use AI')}
        </LoadingButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorElAiMenu}
        open={menuOpen}
        onClose={handleClose}
        elevation={1}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {user?.role?.includes(UserRole.Creator) && (
          <MenuItem
            disabled={
              postState?.post?.type === PostType.AdvocacyParent &&
              !(user?.brand?._id || user?.brandId)
            }
            onClick={() => {
              handleClose();
              handleGenerateIdea();
            }}
            disableRipple
            sx={{
              position: 'relative', // Required for positioning the link
            }}
          >
            <ListItemIcon>
              <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ style: text }}>
              {t('Recieve a post idea')}
              {postState?.post?.type === PostType.AdvocacyParent &&
                !(user?.brand?._id || user?.brandId) && (
                  <Typography
                    variant="caption"
                    fontSize={'13px'}
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering MenuItem's disabled behavior
                      navigate('/settings');
                    }}
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      mt: 1,
                      display: 'block',
                      fontWeight: 'bold',
                      pointerEvents: 'auto', // Override to make clickable
                    }}
                  >
                    {t('Create a brand first')}
                  </Typography>
                )}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          disabled={
            postState?.post?.type === PostType.AdvocacyParent &&
            !(user?.brand?._id || user?.brandId)
          }
          onClick={() => {
            setAiAssistOpen(true);
            handleClose();
          }}
          disableRipple
          sx={{
            position: 'relative', // Required for positioning the link
          }}
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Generate content')}
            {postState?.post?.type === PostType.AdvocacyParent &&
              !(user?.brand?._id || user?.brandId) && (
                <Typography
                  variant="caption"
                  fontSize={'13px'}
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering MenuItem's disabled behavior
                    navigate('/settings');
                  }}
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    mt: 1,
                    display: 'block',
                    fontWeight: 'bold',
                    pointerEvents: 'auto', // Override to make clickable
                  }}
                >
                  {t('Create a brand first')}
                </Typography>
              )}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            postState?.handlers?.handleSaveAsDraft({});
            navigate('/insights');
          }}
          disableRipple
          sx={{
            position: 'relative', // Required for positioning the link
          }}
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Get inspiration from viral posts')}
          </ListItemText>
        </MenuItem>

        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCompleteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <ReadMore fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Complete content')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleRewriteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <EditOutlined fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Rewrite content')}
          </ListItemText>
        </MenuItem>

        <MenuItem
          disabled={content.length === 0}
          onClick={handleTranslateMenuOpen} // Open the submenu when "Translate content" is clicked
          disableRipple
        >
          <ListItemIcon>
            <TranslateIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Translate content')}
          </ListItemText>
        </MenuItem>

        <Popover
          id="translate-menu"
          open={translateMenuOpen}
          anchorEl={translateMenuAnchorEl}
          onClose={handleTranslateMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disablePortal
        >
          <MenuItem
            onClick={() => {
              handleTranslateContent('en');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>English</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('es');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>Spanish</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('es-latam');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              Spanish (Latam)
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pt-br');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              Portuguese (Brazil)
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pt-pt');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>
              Portuguese (Portugal)
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('fr');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>French</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('de');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>German</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('it');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>Italian</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('pl');
              handleTranslateMenuClose();
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>Polish</ListItemText>
          </MenuItem>
        </Popover>

        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCorrectGrammarSpell();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <SpellcheckIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Correct grammar and spell')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AIAssistantMenu;
