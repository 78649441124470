import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState, useEffect } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import SearchIcon from '@mui/icons-material/Search';
import BrandsTable from '../../components/Tables/BrandsTable';
import BrandEditor from '../../components/BrandComponents/BrandEditor';
import { BrandContext } from '../../context/BrandContext';
import { Brand, Group } from '../../__generated__/graphql';
import GroupsBrandTable from '../../components/Tables/GroupsBrandTable';
import BrandGroupEditor from '../../components/BrandComponents/BrandGroupEditor';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_GROUP_BY_USER } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { Groups } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Brands = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isMobile } = useContext(DeviceContext);
  const {
    brandSelected,
    handleBrandSelected,
    dataBrands,
    handleOpenModal,
    shellGroups,
    groupSelected,
    handleGroupSelected,
    refetchGroups,
    resetSelectedOptions,
  } = useContext(BrandContext);

  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);

  const [nameFilter, setNameFilter] = useState<string>('');
  const [newGroupModal, setNewGroupModal] = useState<boolean>(false);
  const [tab, setTab] = useState(0);

  const [createGroup] = useMutation(CREATE_GROUP_BY_USER);

  const schema = yup.object().shape({
    name: yup.string().required(t('Name is required')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const filteredBrands = useMemo(
    () =>
      dataBrands?.filter((brand) =>
        brand!.name.toLowerCase().includes(nameFilter.toLowerCase()),
      ),
    [nameFilter, dataBrands],
  );

  const filteredGroups = useMemo(
    () =>
      shellGroups?.shellGroups?.filter((group) =>
        group!.name.toLowerCase().includes(nameFilter.toLowerCase()),
      ),
    [nameFilter, shellGroups],
  );

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const handleCloseModal = () => {
    reset();
    setNewGroupModal(false);
  };

  const onSubmitCreateGroup = (data: { name: string }) => {
    createGroup({
      variables: {
        input: {
          name: data.name,
        },
      },
      onCompleted: (data) => {
        if (data.createGroup?.success) {
          setTab(1);
          setSuccessMessage(data.createGroup?.message || t('Group created successfully'));
          handleGroupSelected(data.createGroup?.group as Group);
          refetchGroups();
        } else {
          setErrorMessage(data.createGroup?.message || t('Error creating group'));
        }
        handleCloseModal();
      },
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setNameFilter('');
    resetSelectedOptions();
  };

  useEffect(() => {
    if (location.state?.brand) {
      handleBrandSelected(location.state.brand);
    }
    // Leave like this to avoid improper functionality when selecting a brand after comming back from the Add one signature (from the TextEditor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add an empty dependency array to run only once

  return (
    <Box>
      <Stack direction="column" p={4} alignItems={isMobile ? 'center' : 'start'}>
        <Stack direction="row" width="100%" textAlign="center" marginBottom="10px">
          <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
            {t('Brands')}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '17px 25px',
                borderRadius: '8px',
                width: '100%',
                boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
              }}
            >
              <TextField
                placeholder={t('Search')}
                onChange={onSearch}
                value={nameFilter}
                InputProps={{
                  style: {
                    height: '40px',
                    borderRadius: '8px',
                    width: '320px',
                    backgroundColor: 'rgba(244, 244, 244, 1)',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <Stack direction="row" gap={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="new-user-button"
                  onClick={() => setNewGroupModal(true)}
                >
                  <Groups sx={{ marginRight: '10px' }} />
                  <Typography fontWeight="bold">{t('Create group')}</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-testid="new-user-button"
                  sx={{ width: 'fit-content' }}
                  onClick={handleOpenModal}
                >
                  <AddIcon sx={{ marginRight: '10px' }} />
                  <Typography fontWeight="bold">{t('Create new brand')}</Typography>
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} sx={{ height: '100%' }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              sx={{ background: 'transparent', marginBottom: 1 }}
            >
              <Tab
                label={t('Brands')}
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'text.primary',
                }}
              />
              <Tab
                label={t('Groups')}
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: 'text.primary',
                }}
              />
            </Tabs>
            <TabPanel value={tab} index={0} sx={{ p: 0 }}>
              <BrandsTable
                filteredBrands={filteredBrands as Brand[]}
                handleBrandSelected={(brand) => {
                  window.scrollTo(0, 0);
                  handleBrandSelected(brand);
                  if (location.state) {
                    location.state.openEssentialInfoBrands = false;
                  }
                }}
                brandSelected={brandSelected}
              />
            </TabPanel>
            <TabPanel value={tab} index={1} sx={{ p: 0 }}>
              <GroupsBrandTable
                shellGroups={filteredGroups as Group[]}
                handleGroupSelected={(brand) => {
                  window.scrollTo(0, 0);
                  handleGroupSelected(brand);
                }}
                groupSelected={groupSelected}
                setNewGroupModal={setNewGroupModal}
              />
            </TabPanel>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            lg={8}
            sx={{
              height: '100%',
              marginTop: 6,
            }}
          >
            {brandSelected && <BrandEditor brandSelected={brandSelected} />}
            {groupSelected && <BrandGroupEditor groupSelected={groupSelected} />}
            {!brandSelected && !groupSelected && (
              <Card
                sx={{
                  borderRadius: '20px',
                  padding: '20px',
                  width: '100%',
                  height: '70vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  src="../images/cajaBrand.png"
                  alt="onboarding"
                  style={{ width: '85px', height: '85px' }}
                />
                <Typography
                  sx={{
                    width: '50%',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {tab === 0
                    ? dataBrands?.length > 0
                      ? t(
                          "Yet to choose a brand. Please select one to start working more efficiently. By selecting a brand and providing the required information, you'll optimize your workflow and make the most of our tools.",
                        )
                      : t(
                          "You don't have any brands to manage yet. Add one now and input the data here so you can start working more effectively.",
                        )
                    : shellGroups?.shellGroups && shellGroups?.shellGroups?.length > 0
                    ? t(
                        "Yet to choose a group. Please select one to start working more efficiently. By selecting a group and providing the required information, you'll optimize your workflow and make the most of our tools.",
                      )
                    : t(
                        "You don't have any groups to manage yet. Add one now and input the data here so you can start working more effectively.",
                      )}
                </Typography>
              </Card>
            )}
          </Grid>
        </Grid>
      </Stack>
      <Modal
        open={newGroupModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: '20px',
              fontWeight: 'bold',
            }}
          >
            {t('New group')}
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Name')}
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
                sx={{ marginBottom: '20px' }}
              />
            )}
          />
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button variant="outlined" onClick={handleCloseModal}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmitCreateGroup)}
            >
              {t('Create')}
            </Button>
          </Stack>
        </Card>
      </Modal>
    </Box>
  );
};

export default Brands;
