import { AutoAwesome, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ArticleType, Insight, PostContentMedia } from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { getMimeType } from '../../utils';
import PostMediaDisplay from '../PostComponents/PostMediaDisplay';
import { useTranslation } from 'react-i18next';

interface Props {
  insight: Insight | null;
  onClose: () => void;
  handleCreatePostDraft: (
    type: ArticleType,
    content: string,
    media: PostContentMedia[],
  ) => void;
}

const AddPerspectiveInsightModal = ({
  insight,
  onClose,
  handleCreatePostDraft,
}: Props) => {
  const { t } = useTranslation();
  const { postState } = useContext(PostContext);

  const defaultValue = `${insight?.content || insight?.description}${
    insight?.type === ArticleType.Article && insight?.link
      ? `\n\nSource: ${insight?.source} (${insight?.link})`
      : ''
  }`;

  const defaultMedia: PostContentMedia[] =
    insight?.imgUrl || insight?.videoUrl
      ? [
          {
            url: insight?.imgUrl || insight?.videoUrl,
            type: getMimeType((insight?.imgUrl || insight?.videoUrl)!),
          },
        ]
      : [];

  const [content, setContent] = useState<string>(defaultValue);
  const [media, setMedia] = useState<PostContentMedia[]>(defaultMedia);

  const { isMobile } = useContext(DeviceContext);

  const handleUploadMedia = (media: PostContentMedia[]) => {
    setMedia(media);
    postState?.handlers.handleUploadMedia(media);
  };

  const handleDeleteMedia = (mediaIndex: number) => {
    setMedia(media.filter((_, index) => index !== mediaIndex));
    postState?.handlers.handleDeleteMedia(mediaIndex);
  };

  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      disableEscapeKeyDown
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ margin: 'none' }}
      onClose={onClose}
      data-testid="insight-modal"
      scroll="body"
      maxWidth="md"
      PaperProps={{
        style: {
          maxHeight: isMobile ? '100vh' : 'calc(100vh - 64px)',
        },
      }}
    >
      <DialogTitle>
        <Typography fontWeight={600} fontSize={20}>
          {t('Include your perspective on the insight')}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 15,
          top: 15,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          marginBottom={1}
          gap={3}
        >
          <Typography variant="body1">
            {t(
              'Your opinion, relevant data and statements from the insight are the key to generate the draft',
            )}
          </Typography>
          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={() => window.open(insight?.link!, '_blank')}
          >
            {t('View')} {insight?.type === ArticleType.Article ? 'full article' : 'post'}
          </Button>
        </Stack>
        <Stack direction={'column'} sx={{ width: '100%' }} gap={1}>
          <TextField
            value={content}
            onChange={(event) => setContent(event.target.value)}
            multiline
            fullWidth
            rows={10}
            sx={{
              outline: 'none',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#BBB',
                },
                '&:hover fieldset': {
                  borderColor: '#BBB',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#BBB',
                  borderWidth: '1px',
                },
              },
            }}
          />
          <PostMediaDisplay
            media={media}
            onUploadedMedia={handleUploadMedia}
            onDeletedMedia={handleDeleteMedia}
          />
          <Stack direction="row" justifyContent="flex-end">
            {insight && (
              <Tooltip title="Generate a draft with AI" enterDelay={1000} placement="top">
                <span>
                  <LoadingButton
                    startIcon={<AutoAwesome />}
                    loadingPosition="start"
                    onClick={() => {
                      const insightInput = `Title: "${insight?.title}"\n\nDescription: "${insight?.description}"\n\nContent: "${content}"\n\nLink: "${insight.link}"`;
                      handleCreatePostDraft(insight.type!, insightInput, media);
                    }}
                    variant="contained"
                    sx={{ width: 'fit-content' }}
                    disabled={content === defaultValue}
                  >
                    {t('Generate draft')}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddPerspectiveInsightModal;
