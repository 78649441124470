import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import * as IconsMaterial from '@mui/icons-material';

interface Props {
  text: string;
  iconName: string;
  onClick: () => void;
  isActive?: boolean;
  isSmallDevice?: boolean;
  avatar?: boolean;
  rotateIcon?: boolean;
  tooltip?: string;
  isTrial?: boolean;
  children?: React.ReactNode;
}

const DrawerListItem = ({
  text,
  iconName,
  onClick,
  isActive = false,
  isSmallDevice = false,
  avatar = false,
  rotateIcon,
  tooltip = '',
  isTrial = false,
  children,
}: Props) => {
  const Icon = IconsMaterial[iconName as keyof typeof IconsMaterial] as
    | SvgIconComponent
    | undefined;

  return (
    <Tooltip title={tooltip} placement="right">
      <ListItem
        key={text}
        disablePadding
        onClick={onClick}
        disabled={isTrial}
        sx={
          isActive
            ? {
                background: '#E7EBF0',
                color: 'primary.dark',
                width: '90%',
                marginX: 'auto',
                borderRadius: '9px',
              }
            : { width: '90%', marginX: 'auto' }
        }
        data-testid={isActive ? 'active-item' : ''}
        data-cy={`drawer-${text.toLowerCase()}-item`}
      >
        <ListItemButton sx={{ borderRadius: '9px' }}>
          <ListItemIcon sx={{ marginLeft: '6px', minWidth: '40px' }}>
            {!!avatar && (
              <Avatar sx={{ bgcolor: '#FFD8EB', color: 'grey.300' }}>
                {text.charAt(0)}
              </Avatar>
            )}
            {!!Icon && (
              <Icon
                style={rotateIcon ? { transform: 'rotate(90deg)' } : {}}
                sx={isActive ? { color: 'primary.dark' } : {}}
              />
            )}
            {isTrial && (
              <IconsMaterial.Lock
                sx={{
                  color: 'primary.dark',
                  position: 'absolute',
                  right: '0',
                  // top: '6',
                  marginTop: '2px',
                  fontSize: '1rem',
                }}
              />
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize={14}>{text}</Typography>
          </ListItemText>
          {children}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

export default DrawerListItem;
