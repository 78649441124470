import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import enLocale from 'dayjs/locale/en';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  date?: Date;
  maxDate?: Date;
  withTimestamp?: boolean;
  open: boolean;
  anchorElement?: HTMLElement | null;
  onClose?: () => void;
  onSubmit?: (date: Date) => void;
  disablePast?: boolean;
  primaryActionTitle: string;
  transformOrigin?: string;
  isMobile?: boolean;
  tentative?: boolean;
}

const DatePicker = (props: Props) => {
  const today = new Date();
  today.setHours(today.getHours() + 1);
  const {
    date: defaultDate,
    open,
    anchorElement,
    onClose,
    onSubmit,
    disablePast = true,
    transformOrigin = 'center bottom',
    isMobile,
    withTimestamp = true,
    tentative = false,
  } = props;
  let currentDate = dayjs(defaultDate || today);

  const [date, setDate] = useState<Dayjs>(currentDate);
  const [hour, setHour] = useState<string>(currentDate.hour().toString());
  const [minute, setMinute] = useState<string>(currentDate.minute().toString());

  const disabled = disablePast
    ? dayjs(date.toDate().setHours(parseInt(hour), parseInt(minute))).isBefore(dayjs())
    : false;

      const disabledMaxDate =
      !tentative &&
      (props.maxDate
        ? dayjs(date.toDate().setHours(parseInt(hour), parseInt(minute))).isAfter(dayjs(props.maxDate))
        : false);

  function onHourChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value);

    if (isNaN(value)) {
      setHour('0');
      return;
    }

    if (value > 23) {
      setHour('23');
      return;
    }

    if (value < 0) {
      setHour('0');
      return;
    }

    setHour(value.toString());
  }

  function onMinuteChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value);

    if (isNaN(value)) {
      setMinute('0');
      return;
    }

    if (value > 59) {
      setMinute('59');
      return;
    }

    if (value < 0) {
      setMinute('0');
      return;
    }

    setMinute(value.toString());
  }

  function handleSubmit() {
    const datetime = date!.toDate().setHours(parseInt(hour), parseInt(minute));
    onSubmit?.(new Date(datetime));
  }

  const timeInputContainerStyles = {
    marginBottom: 3,
    marginLeft: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  const actionButtonsContainerStyles = {
    textAlign: 'right',
    paddingBottom: '10px',
    paddingRight: '10px',
  };

  const customLocale = {
    ...enLocale,
    weekStart: 1,
  };

  dayjs.locale('en-custom', customLocale);

  const { t } = useTranslation();

  return (
    <Popper
      open={open}
      anchorEl={anchorElement}
      placement={isMobile ? 'top' : 'left-end'}
      role={undefined}
      transition
      disablePortal
      sx={{ zIndex: 100 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin }}>
          <Paper
            sx={{
              my: 1,
              borderRadius: '8px',
              boxShadow:
                '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
            }}
          >
            <ClickAwayListener onClickAway={() => onClose?.()}>
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'en-custom'}
                >
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={date}
                    onChange={(value) => setDate(value!)}
                    maxDate={props.maxDate ? dayjs(props.maxDate) : null}
                    disablePast={disablePast}
                    showDaysOutsideCurrentMonth
                    renderInput={(params: any) => <TextField {...params} />}
                    views={['day']}
                  />
                </LocalizationProvider>
                {withTimestamp && (
                  <Box sx={timeInputContainerStyles}>
                    <TextField
                      value={hour}
                      onChange={onHourChange}
                      sx={{ width: '75px' }}
                      size="small"
                      label="Hour"
                    ></TextField>
                    <Typography sx={{ paddingX: '2px' }}> : </Typography>
                    <TextField
                      value={minute}
                      onChange={onMinuteChange}
                      sx={{ width: '75px' }}
                      size="small"
                      label="Min"
                    ></TextField>
                  </Box>
                )}

                <Box sx={actionButtonsContainerStyles}>
                  <Button sx={{ color: 'text.primary' }} onClick={onClose}>
                    {t('Cancel')}
                  </Button>
                  <Button disabled={disabled || disabledMaxDate} onClick={handleSubmit}>
                    {props.primaryActionTitle}
                  </Button>

                  {disabledMaxDate && (
                    <Typography paddingX={2} fontSize={12} maxWidth={"300px"} textAlign="center">
                      {t('The maximum range to schedule is 30 days')}
                    </Typography>
                  )}
                </Box>
              </>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default DatePicker;
