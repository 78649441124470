import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobDescriptionInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select';
import Slider from '../../components/Slider';
import Switch from '../../components/Switch';
import { useFormContext } from '../../contexts/FormContext';
import { messageTonality } from './consts';
import TextField from '../../components/TextField';
import { useTranslation } from 'react-i18next';

const Step3 = ({ onSubmit }: { onSubmit: (data: JobDescriptionInput) => void }) => {
  const { t } = useTranslation();
  const { updateFormData, formData, prevStep } = useFormContext();
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: formData,
  });

  const handleGoBack = () => {
    const currentData = getValues();
    updateFormData(currentData);
    prevStep();
  };

  const language = [
    { label: t('Español'), value: 'Español' },
    { label: t('Inglés'), value: 'Inglés' },
  ];

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <Select
        options={language}
        name="language"
        label={t('¿En que idioma quieres hacer esta oferta?')}
        required
        control={control}
      />
      <InputLabel
        label={t('¿Cómo describirías el tono o estilo de la oferta?')}
        required
      />
      <Stack direction={'column'} gap={'18px'}>
        {messageTonality.map((tonality) => {
          return (
            <Slider
              key={tonality.name}
              label={t(tonality.label)}
              name={`tone.${tonality.name}`}
              emoji={tonality.emoji}
              control={control}
            />
          );
        })}
      </Stack>
      <InputLabel label={t('¿Quieres incluir emojis en la oferta?')} required />
      <Switch label={t('Quiero emojis en la oferta')} name="emojis" control={control} />
      <TextField
        label={t('¿Te gustaría evitar incluir ciertas palabras en la oferta de empleo?')}
        name="negativesWords"
        placeholder={t('Ej: Experto, Increible')}
        control={control}
      />
      <FormActions
        isValid={formState.isValid}
        handleGoBack={handleGoBack}
        buttonId="lm-job-description-step-3-button"
      />
    </Stack>
  );
};

export default Step3;
