import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobDescriptionIsproxInput } from '../../../__generated__/graphql';
import FormActions from '../../components/FormActions';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { colorPrincipal, colorSecundario, jobModalityOptions } from './consts';
import { useFormContext } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';

const Step1 = ({
  onSubmit,
  defaultValues,
}: {
  onSubmit: (data: JobDescriptionIsproxInput) => void;
  defaultValues: JobDescriptionIsproxInput;
}) => {
  const { t } = useTranslation();
  const { formData } = useFormContext();
  const { control, handleSubmit, formState } = useForm<JobDescriptionIsproxInput>({
    defaultValues: {
      ...defaultValues,
      ...formData,
    },
  });

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      overflow={'auto'}
      gap={'32px'}
    >
      <TextField
        control={control}
        name="company"
        placeholder={t('Ej: Tecnología')}
        label={t('¿En qué sector se ofrece el empleo?')}
        required
        borderColor={colorPrincipal}
      />
      <TextField
        control={control}
        name="title"
        placeholder={t('Ej: Diseñador UX/UI')}
        label={t('¿Cuál es el título del puesto para el empleo que estás ofreciendo?')}
        required
        borderColor={colorPrincipal}
      />
      <TextField
        control={control}
        name="requirements"
        multiline
        placeholder={t('Ejemplo: Un mínimo de 1 año. Hablar inglés.')}
        label={t('¿Qué requisitos se requieren para esta posición?')}
        borderColor={colorPrincipal}
      />
      <Select
        options={jobModalityOptions.map((attr) => ({
          label: t(attr.label),
          value: attr.value,
        }))}
        control={control}
        name="modality"
        placeholder={t('Ej: Híbrido')}
        label={t('Selecciona el formato del empleo')}
        borderColor={colorPrincipal}
      />
      <TextField
        control={control}
        name="benefits"
        multiline
        placeholder={t('Ejemplo: Trabajo remoto, días libres en cumpleaños, etc')}
        label={t(
          '¿Cuáles son los beneficios? Puedes incluir rango salarial si lo crees pertinente',
        )}
        borderColor={colorPrincipal}
      />
      <FormActions
        isValid={formState.isValid}
        firstStep
        buttonId="lm-job-description-step-1-button"
        colorButton={colorPrincipal}
        colorHover={colorSecundario}
      />
    </Stack>
  );
};

export default Step1;
