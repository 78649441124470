import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Thumbnail } from '../../__generated__/graphql';
import React from 'react';
import { PostStatus } from '../../hooks/usePost';

interface Props {
  thumbnail: Thumbnail;
  status?: PostStatus | undefined;
  hideThumbnail?: () => void;
  preview?: boolean;
}

const ThumbnailCard = ({ thumbnail, status, hideThumbnail, preview }: Props) => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        background: '#ECF3F9',
        boxShadow: 0,
        borderRadius: '4px',
        padding: '9px 14px 9px 14px',
      }}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <CardMedia
          component="img"
          sx={{ width: 100, height: 100, borderRadius: 2, cursor: 'pointer' }}
          image={(thumbnail?.media?.url as string) || ''}
          alt={(thumbnail?.title as string) || ''}
          onClick={() => {
            if (thumbnail?.source) {
              window.open(thumbnail.source, '_blank');
            }
          }}
        />
        <CardContent>
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', marginBottom: 1, cursor: 'pointer' }}
            onClick={() => {
              if (thumbnail?.source) {
                window.open(thumbnail.source, '_blank');
              }
            }}
          >
            {thumbnail?.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              if (thumbnail?.source) {
                window.open(thumbnail.source, '_blank');
              }
            }}
          >
            {thumbnail?.source?.replace(/https?:\/\/(www\.)?|\/+$/g, '')}
          </Typography>
        </CardContent>
      </Stack>
      {!preview && (
        <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={2}>
          <IconButton
            disabled={status?.isEditDisabled}
            size="small"
            // onClick={postState?.handlers.handleHideThumbnail}
            onClick={hideThumbnail}
          >
            <Close fontSize="medium" />
          </IconButton>
          <Tooltip title={'The publication includes the thumbnail'}>
            <InfoOutlined fontSize="medium" sx={{ color: '#0000008F' }} />
          </Tooltip>
        </Stack>
      )}
    </Card>
  );
};

export default ThumbnailCard;
