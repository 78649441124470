import { useMutation } from '@apollo/client';
import { Alert } from '@mui/material';
import { useState } from 'react';
import { RealEstateDescriptionInput } from '../../../__generated__/graphql';
import { CREATE_REAL_STATE_DESCRIPTION } from '../../../graphql/mutations';
import { useFormContext } from '../../contexts/FormContext';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { useTranslation } from 'react-i18next';

const FormSteps = () => {
  const { t } = useTranslation();
  const { currentStep, updateFormData, nextStep } = useFormContext();
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createDescription, { loading, error }] = useMutation(
    CREATE_REAL_STATE_DESCRIPTION,
  );

  const handlePartialSubmit = (data: RealEstateDescriptionInput) => {
    updateFormData(data);
    nextStep();
  };

  const handleSubmit = (data: RealEstateDescriptionInput) => {
    createDescription({
      variables: {
        input: {
          ...data,
          propertySize: Number(data.propertySize),
          propertyRooms: Number(data.propertyRooms),
          propertyBathrooms: Number(data.propertyBathrooms),
          propertyServiceBathrooms: Number(data.propertyServiceBathrooms),
        },
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onCompleted: () => {
        setSent(true);
      },
    });
  };

  const defaultValues = {
    propertyCompany: '',
    propertyName: '',
    propertyState: '',
    propertySize: '' as any,
    propertyDescription: '',
    propertyAddress: '',
    propertyRooms: '' as any,
    propertyBathrooms: '' as any,
    propertyServiceBathrooms: '' as any,
    propertyAttributes: [],
    propertyOtherAttributes: '',
    propertyConditions: '',
    language: '',
    tone: {
      friendly: 0,
      personal: 0,
      formal: 0,
      professional: 0,
      commercial: 0,
      creative: 0,
    },
    negativesWords: '',
    name: '',
    surname: '',
    email: '',
  };

  const steps = [
    <Step1 onSubmit={handlePartialSubmit} defaultValues={defaultValues} />,
    <Step2 onSubmit={handlePartialSubmit} />,
    <Step3 onSubmit={handlePartialSubmit} />,
    <Step4 onSubmit={handleSubmit} loading={loading} />,
  ];

  const submitFeedback = (
    <Alert
      sx={{ borderRadius: '8px', backgroundColor: '#4CAF50' }}
      variant="filled"
      severity={error ? 'error' : 'success'}
    >
      {error
        ? t('Ha ocurrido un error.') + ` ${errorMessage}`
        : t(
            'Estamos generando tu oferta personalizada. En unos minutos la recibirás en tu email.',
          )}
    </Alert>
  );

  return <>{!sent ? steps[currentStep] : submitFeedback}</>;
};

export default FormSteps;
